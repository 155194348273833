// Auto-generated by Stone, do not modify.
var routes = {};

/**
 * Sets a user's profile photo.
 * Route attributes:
 *   scope: account_info.write
 * @function Dropbox#accountSetProfilePhoto
 * @arg {AccountSetProfilePhotoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AccountSetProfilePhotoResult>, DropboxResponseError.<AccountSetProfilePhotoError>>}
 */
routes.accountSetProfilePhoto = function (arg) {
  return this.request('account/set_profile_photo', arg, 'user', 'api', 'rpc', 'account_info.write');
};

/**
 * Creates an OAuth 2.0 access token from the supplied OAuth 1.0 access token.
 * @function Dropbox#authTokenFromOauth1
 * @arg {AuthTokenFromOAuth1Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AuthTokenFromOAuth1Result>, DropboxResponseError.<AuthTokenFromOAuth1Error>>}
 */
routes.authTokenFromOauth1 = function (arg) {
  return this.request('auth/token/from_oauth1', arg, 'app', 'api', 'rpc', null);
};

/**
 * Disables the access token used to authenticate the call. If there is a
 * corresponding refresh token for the access token, this disables that refresh
 * token, as well as any other access tokens for that refresh token.
 * @function Dropbox#authTokenRevoke
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<void>>}
 */
routes.authTokenRevoke = function () {
  return this.request('auth/token/revoke', null, 'user', 'api', 'rpc', null);
};

/**
 * This endpoint performs App Authentication, validating the supplied app key
 * and secret, and returns the supplied string, to allow you to test your code
 * and connection to the Dropbox API. It has no other effect. If you receive an
 * HTTP 200 response with the supplied query, it indicates at least part of the
 * Dropbox API infrastructure is working and that the app key and secret valid.
 * @function Dropbox#checkApp
 * @arg {CheckEchoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<CheckEchoResult>, DropboxResponseError.<void>>}
 */
routes.checkApp = function (arg) {
  return this.request('check/app', arg, 'app', 'api', 'rpc', null);
};

/**
 * This endpoint performs User Authentication, validating the supplied access
 * token, and returns the supplied string, to allow you to test your code and
 * connection to the Dropbox API. It has no other effect. If you receive an HTTP
 * 200 response with the supplied query, it indicates at least part of the
 * Dropbox API infrastructure is working and that the access token is valid.
 * Route attributes:
 *   scope: account_info.read
 * @function Dropbox#checkUser
 * @arg {CheckEchoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<CheckEchoResult>, DropboxResponseError.<void>>}
 */
routes.checkUser = function (arg) {
  return this.request('check/user', arg, 'user', 'api', 'rpc', 'account_info.read');
};

/**
 * Removes all manually added contacts. You'll still keep contacts who are on
 * your team or who you imported. New contacts will be added when you share.
 * Route attributes:
 *   scope: contacts.write
 * @function Dropbox#contactsDeleteManualContacts
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<void>>}
 */
routes.contactsDeleteManualContacts = function () {
  return this.request('contacts/delete_manual_contacts', null, 'user', 'api', 'rpc', 'contacts.write');
};

/**
 * Removes manually added contacts from the given list.
 * Route attributes:
 *   scope: contacts.write
 * @function Dropbox#contactsDeleteManualContactsBatch
 * @arg {ContactsDeleteManualContactsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<ContactsDeleteManualContactsError>>}
 */
routes.contactsDeleteManualContactsBatch = function (arg) {
  return this.request('contacts/delete_manual_contacts_batch', arg, 'user', 'api', 'rpc', 'contacts.write');
};

/**
 * Add property groups to a Dropbox file. See templates/add_for_user or
 * templates/add_for_team to create new templates.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesPropertiesAdd
 * @arg {FilePropertiesAddPropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesAddPropertiesError>>}
 */
routes.filePropertiesPropertiesAdd = function (arg) {
  return this.request('file_properties/properties/add', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Overwrite property groups associated with a file. This endpoint should be
 * used instead of properties/update when property groups are being updated via
 * a "snapshot" instead of via a "delta". In other words, this endpoint will
 * delete all omitted fields from a property group, whereas properties/update
 * will only delete fields that are explicitly marked for deletion.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesPropertiesOverwrite
 * @arg {FilePropertiesOverwritePropertyGroupArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesInvalidPropertyGroupError>>}
 */
routes.filePropertiesPropertiesOverwrite = function (arg) {
  return this.request('file_properties/properties/overwrite', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Permanently removes the specified property group from the file. To remove
 * specific property field key value pairs, see properties/update. To update a
 * template, see templates/update_for_user or templates/update_for_team. To
 * remove a template, see templates/remove_for_user or
 * templates/remove_for_team.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesPropertiesRemove
 * @arg {FilePropertiesRemovePropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesRemovePropertiesError>>}
 */
routes.filePropertiesPropertiesRemove = function (arg) {
  return this.request('file_properties/properties/remove', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Search across property templates for particular property field values.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filePropertiesPropertiesSearch
 * @arg {FilePropertiesPropertiesSearchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesPropertiesSearchResult>, DropboxResponseError.<FilePropertiesPropertiesSearchError>>}
 */
routes.filePropertiesPropertiesSearch = function (arg) {
  return this.request('file_properties/properties/search', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Once a cursor has been retrieved from properties/search, use this to paginate
 * through all search results.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filePropertiesPropertiesSearchContinue
 * @arg {FilePropertiesPropertiesSearchContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesPropertiesSearchResult>, DropboxResponseError.<FilePropertiesPropertiesSearchContinueError>>}
 */
routes.filePropertiesPropertiesSearchContinue = function (arg) {
  return this.request('file_properties/properties/search/continue', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Add, update or remove properties associated with the supplied file and
 * templates. This endpoint should be used instead of properties/overwrite when
 * property groups are being updated via a "delta" instead of via a "snapshot" .
 * In other words, this endpoint will not delete any omitted fields from a
 * property group, whereas properties/overwrite will delete any fields that are
 * omitted from a property group.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesPropertiesUpdate
 * @arg {FilePropertiesUpdatePropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesUpdatePropertiesError>>}
 */
routes.filePropertiesPropertiesUpdate = function (arg) {
  return this.request('file_properties/properties/update', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Add a template associated with a team. See properties/add to add properties
 * to a file or folder. Note: this endpoint will create team-owned templates.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#filePropertiesTemplatesAddForTeam
 * @arg {FilePropertiesAddTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesAddTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.filePropertiesTemplatesAddForTeam = function (arg) {
  return this.request('file_properties/templates/add_for_team', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Add a template associated with a user. See properties/add to add properties
 * to a file. This endpoint can't be called on a team member or admin's behalf.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesTemplatesAddForUser
 * @arg {FilePropertiesAddTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesAddTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.filePropertiesTemplatesAddForUser = function (arg) {
  return this.request('file_properties/templates/add_for_user', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Get the schema for a specified template.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#filePropertiesTemplatesGetForTeam
 * @arg {FilePropertiesGetTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesGetTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesGetForTeam = function (arg) {
  return this.request('file_properties/templates/get_for_team', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Get the schema for a specified template. This endpoint can't be called on a
 * team member or admin's behalf.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filePropertiesTemplatesGetForUser
 * @arg {FilePropertiesGetTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesGetTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesGetForUser = function (arg) {
  return this.request('file_properties/templates/get_for_user', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Get the template identifiers for a team. To get the schema of each template
 * use templates/get_for_team.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#filePropertiesTemplatesListForTeam
 * @returns {Promise.<DropboxResponse<FilePropertiesListTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesListForTeam = function () {
  return this.request('file_properties/templates/list_for_team', null, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Get the template identifiers for a team. To get the schema of each template
 * use templates/get_for_user. This endpoint can't be called on a team member or
 * admin's behalf.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filePropertiesTemplatesListForUser
 * @returns {Promise.<DropboxResponse<FilePropertiesListTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesListForUser = function () {
  return this.request('file_properties/templates/list_for_user', null, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Permanently removes the specified template created from
 * templates/add_for_user. All properties associated with the template will also
 * be removed. This action cannot be undone.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#filePropertiesTemplatesRemoveForTeam
 * @arg {FilePropertiesRemoveTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesRemoveForTeam = function (arg) {
  return this.request('file_properties/templates/remove_for_team', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Permanently removes the specified template created from
 * templates/add_for_user. All properties associated with the template will also
 * be removed. This action cannot be undone.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesTemplatesRemoveForUser
 * @arg {FilePropertiesRemoveTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filePropertiesTemplatesRemoveForUser = function (arg) {
  return this.request('file_properties/templates/remove_for_user', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Update a template associated with a team. This route can update the template
 * name, the template description and add optional properties to templates.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#filePropertiesTemplatesUpdateForTeam
 * @arg {FilePropertiesUpdateTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesUpdateTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.filePropertiesTemplatesUpdateForTeam = function (arg) {
  return this.request('file_properties/templates/update_for_team', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Update a template associated with a user. This route can update the template
 * name, the template description and add optional properties to templates. This
 * endpoint can't be called on a team member or admin's behalf.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filePropertiesTemplatesUpdateForUser
 * @arg {FilePropertiesUpdateTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesUpdateTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.filePropertiesTemplatesUpdateForUser = function (arg) {
  return this.request('file_properties/templates/update_for_user', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Returns the total number of file requests owned by this user. Includes both
 * open and closed file requests.
 * Route attributes:
 *   scope: file_requests.read
 * @function Dropbox#fileRequestsCount
 * @returns {Promise.<DropboxResponse<FileRequestsCountFileRequestsResult>, DropboxResponseError.<FileRequestsCountFileRequestsError>>}
 */
routes.fileRequestsCount = function () {
  return this.request('file_requests/count', null, 'user', 'api', 'rpc', 'file_requests.read');
};

/**
 * Creates a file request for this user.
 * Route attributes:
 *   scope: file_requests.write
 * @function Dropbox#fileRequestsCreate
 * @arg {FileRequestsCreateFileRequestArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsFileRequest>, DropboxResponseError.<FileRequestsCreateFileRequestError>>}
 */
routes.fileRequestsCreate = function (arg) {
  return this.request('file_requests/create', arg, 'user', 'api', 'rpc', 'file_requests.write');
};

/**
 * Delete a batch of closed file requests.
 * Route attributes:
 *   scope: file_requests.write
 * @function Dropbox#fileRequestsDelete
 * @arg {FileRequestsDeleteFileRequestArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsDeleteFileRequestsResult>, DropboxResponseError.<FileRequestsDeleteFileRequestError>>}
 */
routes.fileRequestsDelete = function (arg) {
  return this.request('file_requests/delete', arg, 'user', 'api', 'rpc', 'file_requests.write');
};

/**
 * Delete all closed file requests owned by this user.
 * Route attributes:
 *   scope: file_requests.write
 * @function Dropbox#fileRequestsDeleteAllClosed
 * @returns {Promise.<DropboxResponse<FileRequestsDeleteAllClosedFileRequestsResult>, DropboxResponseError.<FileRequestsDeleteAllClosedFileRequestsError>>}
 */
routes.fileRequestsDeleteAllClosed = function () {
  return this.request('file_requests/delete_all_closed', null, 'user', 'api', 'rpc', 'file_requests.write');
};

/**
 * Returns the specified file request.
 * Route attributes:
 *   scope: file_requests.read
 * @function Dropbox#fileRequestsGet
 * @arg {FileRequestsGetFileRequestArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsFileRequest>, DropboxResponseError.<FileRequestsGetFileRequestError>>}
 */
routes.fileRequestsGet = function (arg) {
  return this.request('file_requests/get', arg, 'user', 'api', 'rpc', 'file_requests.read');
};

/**
 * Returns a list of file requests owned by this user. For apps with the app
 * folder permission, this will only return file requests with destinations in
 * the app folder.
 * Route attributes:
 *   scope: file_requests.read
 * @function Dropbox#fileRequestsListV2
 * @arg {FileRequestsListFileRequestsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsListFileRequestsV2Result>, DropboxResponseError.<FileRequestsListFileRequestsError>>}
 */
routes.fileRequestsListV2 = function (arg) {
  return this.request('file_requests/list_v2', arg, 'user', 'api', 'rpc', 'file_requests.read');
};

/**
 * Returns a list of file requests owned by this user. For apps with the app
 * folder permission, this will only return file requests with destinations in
 * the app folder.
 * Route attributes:
 *   scope: file_requests.read
 * @function Dropbox#fileRequestsList
 * @returns {Promise.<DropboxResponse<FileRequestsListFileRequestsResult>, DropboxResponseError.<FileRequestsListFileRequestsError>>}
 */
routes.fileRequestsList = function () {
  return this.request('file_requests/list', null, 'user', 'api', 'rpc', 'file_requests.read');
};

/**
 * Once a cursor has been retrieved from list_v2, use this to paginate through
 * all file requests. The cursor must come from a previous call to list_v2 or
 * list/continue.
 * Route attributes:
 *   scope: file_requests.read
 * @function Dropbox#fileRequestsListContinue
 * @arg {FileRequestsListFileRequestsContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsListFileRequestsV2Result>, DropboxResponseError.<FileRequestsListFileRequestsContinueError>>}
 */
routes.fileRequestsListContinue = function (arg) {
  return this.request('file_requests/list/continue', arg, 'user', 'api', 'rpc', 'file_requests.read');
};

/**
 * Update a file request.
 * Route attributes:
 *   scope: file_requests.write
 * @function Dropbox#fileRequestsUpdate
 * @arg {FileRequestsUpdateFileRequestArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FileRequestsFileRequest>, DropboxResponseError.<FileRequestsUpdateFileRequestError>>}
 */
routes.fileRequestsUpdate = function (arg) {
  return this.request('file_requests/update', arg, 'user', 'api', 'rpc', 'file_requests.write');
};

/**
 * Returns the metadata for a file or folder. This is an alpha endpoint
 * compatible with the properties API. Note: Metadata for the root folder is
 * unsupported.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesAlphaGetMetadata
 * @deprecated
 * @arg {FilesAlphaGetMetadataArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(FilesFileMetadata|FilesFolderMetadata|FilesDeletedMetadata)>, DropboxResponseError.<FilesAlphaGetMetadataError>>}
 */
routes.filesAlphaGetMetadata = function (arg) {
  return this.request('files/alpha/get_metadata', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Create a new file with the contents provided in the request. Note that the
 * behavior of this alpha endpoint is unstable and subject to change. Do not use
 * this to upload a file larger than 150 MB. Instead, create an upload session
 * with upload_session/start.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesAlphaUpload
 * @deprecated
 * @arg {FilesUploadArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesUploadError>>}
 */
routes.filesAlphaUpload = function (arg) {
  return this.request('files/alpha/upload', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * Copy a file or folder to a different location in the user's Dropbox. If the
 * source path is a folder all its contents will be copied.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyV2
 * @arg {FilesRelocationArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationResult>, DropboxResponseError.<FilesRelocationError>>}
 */
routes.filesCopyV2 = function (arg) {
  return this.request('files/copy_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Copy a file or folder to a different location in the user's Dropbox. If the
 * source path is a folder all its contents will be copied.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopy
 * @deprecated
 * @arg {FilesRelocationArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(FilesFileMetadata|FilesFolderMetadata|FilesDeletedMetadata)>, DropboxResponseError.<FilesRelocationError>>}
 */
routes.filesCopy = function (arg) {
  return this.request('files/copy', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Copy multiple files or folders to different locations at once in the user's
 * Dropbox. This route will replace copy_batch. The main difference is this
 * route will return status for each entry, while copy_batch raises failure if
 * any entry fails. This route will either finish synchronously, or return a job
 * ID and do the async copy job in background. Please use copy_batch/check_v2 to
 * check the job status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyBatchV2
 * @arg {Object} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchV2Launch>, DropboxResponseError.<void>>}
 */
routes.filesCopyBatchV2 = function (arg) {
  return this.request('files/copy_batch_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Copy multiple files or folders to different locations at once in the user's
 * Dropbox. This route will return job ID immediately and do the async copy job
 * in background. Please use copy_batch/check to check the job status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyBatch
 * @deprecated
 * @arg {FilesRelocationBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchLaunch>, DropboxResponseError.<void>>}
 */
routes.filesCopyBatch = function (arg) {
  return this.request('files/copy_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for copy_batch_v2. It returns list
 * of results for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyBatchCheckV2
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchV2JobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesCopyBatchCheckV2 = function (arg) {
  return this.request('files/copy_batch/check_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for copy_batch. If success, it
 * returns list of results for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyBatchCheck
 * @deprecated
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesCopyBatchCheck = function (arg) {
  return this.request('files/copy_batch/check', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Get a copy reference to a file or folder. This reference string can be used
 * to save that file or folder to another user's Dropbox by passing it to
 * copy_reference/save.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyReferenceGet
 * @arg {FilesGetCopyReferenceArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesGetCopyReferenceResult>, DropboxResponseError.<FilesGetCopyReferenceError>>}
 */
routes.filesCopyReferenceGet = function (arg) {
  return this.request('files/copy_reference/get', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Save a copy reference returned by copy_reference/get to the user's Dropbox.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCopyReferenceSave
 * @arg {FilesSaveCopyReferenceArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSaveCopyReferenceResult>, DropboxResponseError.<FilesSaveCopyReferenceError>>}
 */
routes.filesCopyReferenceSave = function (arg) {
  return this.request('files/copy_reference/save', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Create a folder at a given path.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCreateFolderV2
 * @arg {FilesCreateFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesCreateFolderResult>, DropboxResponseError.<FilesCreateFolderError>>}
 */
routes.filesCreateFolderV2 = function (arg) {
  return this.request('files/create_folder_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Create a folder at a given path.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCreateFolder
 * @deprecated
 * @arg {FilesCreateFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFolderMetadata>, DropboxResponseError.<FilesCreateFolderError>>}
 */
routes.filesCreateFolder = function (arg) {
  return this.request('files/create_folder', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Create multiple folders at once. This route is asynchronous for large
 * batches, which returns a job ID immediately and runs the create folder batch
 * asynchronously. Otherwise, creates the folders and returns the result
 * synchronously for smaller inputs. You can force asynchronous behaviour by
 * using the CreateFolderBatchArg.force_async flag.  Use
 * create_folder_batch/check to check the job status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCreateFolderBatch
 * @arg {FilesCreateFolderBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesCreateFolderBatchLaunch>, DropboxResponseError.<void>>}
 */
routes.filesCreateFolderBatch = function (arg) {
  return this.request('files/create_folder_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for create_folder_batch. If
 * success, it returns list of result for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesCreateFolderBatchCheck
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesCreateFolderBatchJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesCreateFolderBatchCheck = function (arg) {
  return this.request('files/create_folder_batch/check', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Delete the file or folder at a given path. If the path is a folder, all its
 * contents will be deleted too. A successful response indicates that the file
 * or folder was deleted. The returned metadata will be the corresponding
 * FileMetadata or FolderMetadata for the item at time of deletion, and not a
 * DeletedMetadata object.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesDeleteV2
 * @arg {FilesDeleteArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesDeleteResult>, DropboxResponseError.<FilesDeleteError>>}
 */
routes.filesDeleteV2 = function (arg) {
  return this.request('files/delete_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Delete the file or folder at a given path. If the path is a folder, all its
 * contents will be deleted too. A successful response indicates that the file
 * or folder was deleted. The returned metadata will be the corresponding
 * FileMetadata or FolderMetadata for the item at time of deletion, and not a
 * DeletedMetadata object.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesDelete
 * @deprecated
 * @arg {FilesDeleteArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(FilesFileMetadata|FilesFolderMetadata|FilesDeletedMetadata)>, DropboxResponseError.<FilesDeleteError>>}
 */
routes.filesDelete = function (arg) {
  return this.request('files/delete', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Delete multiple files/folders at once. This route is asynchronous, which
 * returns a job ID immediately and runs the delete batch asynchronously. Use
 * delete_batch/check to check the job status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesDeleteBatch
 * @arg {FilesDeleteBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesDeleteBatchLaunch>, DropboxResponseError.<void>>}
 */
routes.filesDeleteBatch = function (arg) {
  return this.request('files/delete_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for delete_batch. If success, it
 * returns list of result for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesDeleteBatchCheck
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesDeleteBatchJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesDeleteBatchCheck = function (arg) {
  return this.request('files/delete_batch/check', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Download a file from a user's Dropbox.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesDownload
 * @arg {FilesDownloadArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesDownloadError>>}
 */
routes.filesDownload = function (arg) {
  return this.request('files/download', arg, 'user', 'content', 'download', 'files.content.read');
};

/**
 * Download a folder from the user's Dropbox, as a zip file. The folder must be
 * less than 20 GB in size and any single file within must be less than 4 GB in
 * size. The resulting zip must have fewer than 10,000 total file and folder
 * entries, including the top level folder. The input cannot be a single file.
 * Note: this endpoint does not support HTTP range requests.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesDownloadZip
 * @arg {FilesDownloadZipArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesDownloadZipResult>, DropboxResponseError.<FilesDownloadZipError>>}
 */
routes.filesDownloadZip = function (arg) {
  return this.request('files/download_zip', arg, 'user', 'content', 'download', 'files.content.read');
};

/**
 * Export a file from a user's Dropbox. This route only supports exporting files
 * that cannot be downloaded directly  and whose ExportResult.file_metadata has
 * ExportInfo.export_as populated.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesExport
 * @arg {FilesExportArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesExportResult>, DropboxResponseError.<FilesExportError>>}
 */
routes.filesExport = function (arg) {
  return this.request('files/export', arg, 'user', 'content', 'download', 'files.content.read');
};

/**
 * Return the lock metadata for the given list of paths.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetFileLockBatch
 * @arg {FilesLockFileBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesLockFileBatchResult>, DropboxResponseError.<FilesLockFileError>>}
 */
routes.filesGetFileLockBatch = function (arg) {
  return this.request('files/get_file_lock_batch', arg, 'user', 'api', 'rpc', 'files.content.read');
};

/**
 * Returns the metadata for a file or folder. Note: Metadata for the root folder
 * is unsupported.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesGetMetadata
 * @arg {FilesGetMetadataArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(FilesFileMetadata|FilesFolderMetadata|FilesDeletedMetadata)>, DropboxResponseError.<FilesGetMetadataError>>}
 */
routes.filesGetMetadata = function (arg) {
  return this.request('files/get_metadata', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Get a preview for a file. Currently, PDF previews are generated for files
 * with the following extensions: .ai, .doc, .docm, .docx, .eps, .gdoc,
 * .gslides, .odp, .odt, .pps, .ppsm, .ppsx, .ppt, .pptm, .pptx, .rtf. HTML
 * previews are generated for files with the following extensions: .csv, .ods,
 * .xls, .xlsm, .gsheet, .xlsx. Other formats will return an unsupported
 * extension error.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetPreview
 * @arg {FilesPreviewArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesPreviewError>>}
 */
routes.filesGetPreview = function (arg) {
  return this.request('files/get_preview', arg, 'user', 'content', 'download', 'files.content.read');
};

/**
 * Get a temporary link to stream content of a file. This link will expire in
 * four hours and afterwards you will get 410 Gone. This URL should not be used
 * to display content directly in the browser. The Content-Type of the link is
 * determined automatically by the file's mime type.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetTemporaryLink
 * @arg {FilesGetTemporaryLinkArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesGetTemporaryLinkResult>, DropboxResponseError.<FilesGetTemporaryLinkError>>}
 */
routes.filesGetTemporaryLink = function (arg) {
  return this.request('files/get_temporary_link', arg, 'user', 'api', 'rpc', 'files.content.read');
};

/**
 * Get a one-time use temporary upload link to upload a file to a Dropbox
 * location.  This endpoint acts as a delayed upload. The returned temporary
 * upload link may be used to make a POST request with the data to be uploaded.
 * The upload will then be perfomed with the CommitInfo previously provided to
 * get_temporary_upload_link but evaluated only upon consumption. Hence, errors
 * stemming from invalid CommitInfo with respect to the state of the user's
 * Dropbox will only be communicated at consumption time. Additionally, these
 * errors are surfaced as generic HTTP 409 Conflict responses, potentially
 * hiding issue details. The maximum temporary upload link duration is 4 hours.
 * Upon consumption or expiration, a new link will have to be generated.
 * Multiple links may exist for a specific upload path at any given time.  The
 * POST request on the temporary upload link must have its Content-Type set to
 * "application/octet-stream".  Example temporary upload link consumption
 * request:  curl -X POST
 * https://content.dropboxapi.com/apitul/1/bNi2uIYF51cVBND --header
 * "Content-Type: application/octet-stream" --data-binary @local_file.txt  A
 * successful temporary upload link consumption request returns the content hash
 * of the uploaded data in JSON format.  Example successful temporary upload
 * link consumption response: {"content-hash":
 * "599d71033d700ac892a0e48fa61b125d2f5994"}  An unsuccessful temporary upload
 * link consumption request returns any of the following status codes:  HTTP 400
 * Bad Request: Content-Type is not one of application/octet-stream and
 * text/plain or request is invalid. HTTP 409 Conflict: The temporary upload
 * link does not exist or is currently unavailable, the upload failed, or
 * another error happened. HTTP 410 Gone: The temporary upload link is expired
 * or consumed.  Example unsuccessful temporary upload link consumption
 * response: Temporary upload link has been recently consumed.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesGetTemporaryUploadLink
 * @arg {FilesGetTemporaryUploadLinkArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesGetTemporaryUploadLinkResult>, DropboxResponseError.<void>>}
 */
routes.filesGetTemporaryUploadLink = function (arg) {
  return this.request('files/get_temporary_upload_link', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Get a thumbnail for an image. This method currently supports files with the
 * following file extensions: jpg, jpeg, png, tiff, tif, gif, webp, ppm and bmp.
 * Photos that are larger than 20MB in size won't be converted to a thumbnail.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetThumbnail
 * @arg {FilesThumbnailArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesThumbnailError>>}
 */
routes.filesGetThumbnail = function (arg) {
  return this.request('files/get_thumbnail', arg, 'user', 'content', 'download', 'files.content.read');
};

/**
 * Get a thumbnail for an image. This method currently supports files with the
 * following file extensions: jpg, jpeg, png, tiff, tif, gif, webp, ppm and bmp.
 * Photos that are larger than 20MB in size won't be converted to a thumbnail.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetThumbnailV2
 * @arg {FilesThumbnailV2Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesPreviewResult>, DropboxResponseError.<FilesThumbnailV2Error>>}
 */
routes.filesGetThumbnailV2 = function (arg) {
  return this.request('files/get_thumbnail_v2', arg, 'app, user', 'content', 'download', 'files.content.read');
};

/**
 * Get thumbnails for a list of images. We allow up to 25 thumbnails in a single
 * batch. This method currently supports files with the following file
 * extensions: jpg, jpeg, png, tiff, tif, gif, webp, ppm and bmp. Photos that
 * are larger than 20MB in size won't be converted to a thumbnail.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#filesGetThumbnailBatch
 * @arg {FilesGetThumbnailBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesGetThumbnailBatchResult>, DropboxResponseError.<FilesGetThumbnailBatchError>>}
 */
routes.filesGetThumbnailBatch = function (arg) {
  return this.request('files/get_thumbnail_batch', arg, 'user', 'content', 'rpc', 'files.content.read');
};

/**
 * Starts returning the contents of a folder. If the result's
 * ListFolderResult.has_more field is true, call list_folder/continue with the
 * returned ListFolderResult.cursor to retrieve more entries. If you're using
 * ListFolderArg.recursive set to true to keep a local cache of the contents of
 * a Dropbox account, iterate through each entry in order and process them as
 * follows to keep your local state in sync: For each FileMetadata, store the
 * new entry at the given path in your local state. If the required parent
 * folders don't exist yet, create them. If there's already something else at
 * the given path, replace it and remove all its children. For each
 * FolderMetadata, store the new entry at the given path in your local state. If
 * the required parent folders don't exist yet, create them. If there's already
 * something else at the given path, replace it but leave the children as they
 * are. Check the new entry's FolderSharingInfo.read_only and set all its
 * children's read-only statuses to match. For each DeletedMetadata, if your
 * local state has something at the given path, remove it and all its children.
 * If there's nothing at the given path, ignore this entry. Note:
 * auth.RateLimitError may be returned if multiple list_folder or
 * list_folder/continue calls with same parameters are made simultaneously by
 * same API app for same user. If your app implements retry logic, please hold
 * off the retry until the previous request finishes.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesListFolder
 * @arg {FilesListFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesListFolderResult>, DropboxResponseError.<FilesListFolderError>>}
 */
routes.filesListFolder = function (arg) {
  return this.request('files/list_folder', arg, 'app, user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Once a cursor has been retrieved from list_folder, use this to paginate
 * through all files and retrieve updates to the folder, following the same
 * rules as documented for list_folder.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesListFolderContinue
 * @arg {FilesListFolderContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesListFolderResult>, DropboxResponseError.<FilesListFolderContinueError>>}
 */
routes.filesListFolderContinue = function (arg) {
  return this.request('files/list_folder/continue', arg, 'app, user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * A way to quickly get a cursor for the folder's state. Unlike list_folder,
 * list_folder/get_latest_cursor doesn't return any entries. This endpoint is
 * for app which only needs to know about new files and modifications and
 * doesn't need to know about files that already exist in Dropbox.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesListFolderGetLatestCursor
 * @arg {FilesListFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesListFolderGetLatestCursorResult>, DropboxResponseError.<FilesListFolderError>>}
 */
routes.filesListFolderGetLatestCursor = function (arg) {
  return this.request('files/list_folder/get_latest_cursor', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * A longpoll endpoint to wait for changes on an account. In conjunction with
 * list_folder/continue, this call gives you a low-latency way to monitor an
 * account for file changes. The connection will block until there are changes
 * available or a timeout occurs. This endpoint is useful mostly for client-side
 * apps. If you're looking for server-side notifications, check out our webhooks
 * documentation https://www.dropbox.com/developers/reference/webhooks.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesListFolderLongpoll
 * @arg {FilesListFolderLongpollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesListFolderLongpollResult>, DropboxResponseError.<FilesListFolderLongpollError>>}
 */
routes.filesListFolderLongpoll = function (arg) {
  return this.request('files/list_folder/longpoll', arg, 'noauth', 'notify', 'rpc', 'files.metadata.read');
};

/**
 * Returns revisions for files based on a file path or a file id. The file path
 * or file id is identified from the latest file entry at the given file path or
 * id. This end point allows your app to query either by file path or file id by
 * setting the mode parameter appropriately. In the ListRevisionsMode.path
 * (default) mode, all revisions at the same file path as the latest file entry
 * are returned. If revisions with the same file id are desired, then mode must
 * be set to ListRevisionsMode.id. The ListRevisionsMode.id mode is useful to
 * retrieve revisions for a given file across moves or renames.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesListRevisions
 * @arg {FilesListRevisionsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesListRevisionsResult>, DropboxResponseError.<FilesListRevisionsError>>}
 */
routes.filesListRevisions = function (arg) {
  return this.request('files/list_revisions', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Lock the files at the given paths. A locked file will be writable only by the
 * lock holder. A successful response indicates that the file has been locked.
 * Returns a list of the locked file paths and their metadata after this
 * operation.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesLockFileBatch
 * @arg {FilesLockFileBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesLockFileBatchResult>, DropboxResponseError.<FilesLockFileError>>}
 */
routes.filesLockFileBatch = function (arg) {
  return this.request('files/lock_file_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Move a file or folder to a different location in the user's Dropbox. If the
 * source path is a folder all its contents will be moved. Note that we do not
 * currently support case-only renaming.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMoveV2
 * @arg {FilesRelocationArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationResult>, DropboxResponseError.<FilesRelocationError>>}
 */
routes.filesMoveV2 = function (arg) {
  return this.request('files/move_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Move a file or folder to a different location in the user's Dropbox. If the
 * source path is a folder all its contents will be moved.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMove
 * @deprecated
 * @arg {FilesRelocationArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(FilesFileMetadata|FilesFolderMetadata|FilesDeletedMetadata)>, DropboxResponseError.<FilesRelocationError>>}
 */
routes.filesMove = function (arg) {
  return this.request('files/move', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Move multiple files or folders to different locations at once in the user's
 * Dropbox. Note that we do not currently support case-only renaming. This route
 * will replace move_batch. The main difference is this route will return status
 * for each entry, while move_batch raises failure if any entry fails. This
 * route will either finish synchronously, or return a job ID and do the async
 * move job in background. Please use move_batch/check_v2 to check the job
 * status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMoveBatchV2
 * @arg {FilesMoveBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchV2Launch>, DropboxResponseError.<void>>}
 */
routes.filesMoveBatchV2 = function (arg) {
  return this.request('files/move_batch_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Move multiple files or folders to different locations at once in the user's
 * Dropbox. This route will return job ID immediately and do the async moving
 * job in background. Please use move_batch/check to check the job status.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMoveBatch
 * @deprecated
 * @arg {FilesRelocationBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchLaunch>, DropboxResponseError.<void>>}
 */
routes.filesMoveBatch = function (arg) {
  return this.request('files/move_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for move_batch_v2. It returns list
 * of results for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMoveBatchCheckV2
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchV2JobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesMoveBatchCheckV2 = function (arg) {
  return this.request('files/move_batch/check_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for move_batch. If success, it
 * returns list of results for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesMoveBatchCheck
 * @deprecated
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesRelocationBatchJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesMoveBatchCheck = function (arg) {
  return this.request('files/move_batch/check', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Creates a new Paper doc with the provided content.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesPaperCreate
 * @arg {FilesPaperCreateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesPaperCreateResult>, DropboxResponseError.<FilesPaperCreateError>>}
 */
routes.filesPaperCreate = function (arg) {
  return this.request('files/paper/create', arg, 'user', 'api', 'upload', 'files.content.write');
};

/**
 * Updates an existing Paper doc with the provided content.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesPaperUpdate
 * @arg {FilesPaperUpdateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesPaperUpdateResult>, DropboxResponseError.<FilesPaperUpdateError>>}
 */
routes.filesPaperUpdate = function (arg) {
  return this.request('files/paper/update', arg, 'user', 'api', 'upload', 'files.content.write');
};

/**
 * Permanently delete the file or folder at a given path (see
 * https://www.dropbox.com/en/help/40). If the given file or folder is not yet
 * deleted, this route will first delete it. It is possible for this route to
 * successfully delete, then fail to permanently delete. Note: This endpoint is
 * only available for Dropbox Business apps.
 * Route attributes:
 *   scope: files.permanent_delete
 * @function Dropbox#filesPermanentlyDelete
 * @arg {FilesDeleteArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilesDeleteError>>}
 */
routes.filesPermanentlyDelete = function (arg) {
  return this.request('files/permanently_delete', arg, 'user', 'api', 'rpc', 'files.permanent_delete');
};

/**
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesPropertiesAdd
 * @deprecated
 * @arg {FilePropertiesAddPropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesAddPropertiesError>>}
 */
routes.filesPropertiesAdd = function (arg) {
  return this.request('files/properties/add', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesPropertiesOverwrite
 * @deprecated
 * @arg {FilePropertiesOverwritePropertyGroupArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesInvalidPropertyGroupError>>}
 */
routes.filesPropertiesOverwrite = function (arg) {
  return this.request('files/properties/overwrite', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesPropertiesRemove
 * @deprecated
 * @arg {FilePropertiesRemovePropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesRemovePropertiesError>>}
 */
routes.filesPropertiesRemove = function (arg) {
  return this.request('files/properties/remove', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesPropertiesTemplateGet
 * @deprecated
 * @arg {FilePropertiesGetTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesGetTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filesPropertiesTemplateGet = function (arg) {
  return this.request('files/properties/template/get', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesPropertiesTemplateList
 * @deprecated
 * @returns {Promise.<DropboxResponse<FilePropertiesListTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.filesPropertiesTemplateList = function () {
  return this.request('files/properties/template/list', null, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesPropertiesUpdate
 * @deprecated
 * @arg {FilePropertiesUpdatePropertiesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilePropertiesUpdatePropertiesError>>}
 */
routes.filesPropertiesUpdate = function (arg) {
  return this.request('files/properties/update', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Restore a specific revision of a file to the given path.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesRestore
 * @arg {FilesRestoreArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesRestoreError>>}
 */
routes.filesRestore = function (arg) {
  return this.request('files/restore', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Save the data from a specified URL into a file in user's Dropbox. Note that
 * the transfer from the URL must complete within 5 minutes, or the operation
 * will time out and the job will fail. If the given path already exists, the
 * file will be renamed to avoid the conflict (e.g. myfile (1).txt).
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesSaveUrl
 * @arg {FilesSaveUrlArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSaveUrlResult>, DropboxResponseError.<FilesSaveUrlError>>}
 */
routes.filesSaveUrl = function (arg) {
  return this.request('files/save_url', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Check the status of a save_url job.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesSaveUrlCheckJobStatus
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSaveUrlJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesSaveUrlCheckJobStatus = function (arg) {
  return this.request('files/save_url/check_job_status', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Searches for files and folders. Note: Recent changes will be reflected in
 * search results within a few seconds and older revisions of existing files may
 * still match your query for up to a few days.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesSearch
 * @deprecated
 * @arg {FilesSearchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSearchResult>, DropboxResponseError.<FilesSearchError>>}
 */
routes.filesSearch = function (arg) {
  return this.request('files/search', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Searches for files and folders. Note: search_v2 along with search/continue_v2
 * can only be used to retrieve a maximum of 10,000 matches. Recent changes may
 * not immediately be reflected in search results due to a short delay in
 * indexing. Duplicate results may be returned across pages. Some results may
 * not be returned.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesSearchV2
 * @arg {FilesSearchV2Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSearchV2Result>, DropboxResponseError.<FilesSearchError>>}
 */
routes.filesSearchV2 = function (arg) {
  return this.request('files/search_v2', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Fetches the next page of search results returned from search_v2. Note:
 * search_v2 along with search/continue_v2 can only be used to retrieve a
 * maximum of 10,000 matches. Recent changes may not immediately be reflected in
 * search results due to a short delay in indexing. Duplicate results may be
 * returned across pages. Some results may not be returned.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesSearchContinueV2
 * @arg {FilesSearchV2ContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesSearchV2Result>, DropboxResponseError.<FilesSearchError>>}
 */
routes.filesSearchContinueV2 = function (arg) {
  return this.request('files/search/continue_v2', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Add a tag to an item. A tag is a string. The strings are automatically
 * converted to lowercase letters. No more than 20 tags can be added to a given
 * item.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesTagsAdd
 * @arg {FilesAddTagArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilesAddTagError>>}
 */
routes.filesTagsAdd = function (arg) {
  return this.request('files/tags/add', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Get list of tags assigned to items.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#filesTagsGet
 * @arg {FilesGetTagsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesGetTagsResult>, DropboxResponseError.<FilesBaseTagError>>}
 */
routes.filesTagsGet = function (arg) {
  return this.request('files/tags/get', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Remove a tag from an item.
 * Route attributes:
 *   scope: files.metadata.write
 * @function Dropbox#filesTagsRemove
 * @arg {FilesRemoveTagArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilesRemoveTagError>>}
 */
routes.filesTagsRemove = function (arg) {
  return this.request('files/tags/remove', arg, 'user', 'api', 'rpc', 'files.metadata.write');
};

/**
 * Unlock the files at the given paths. A locked file can only be unlocked by
 * the lock holder or, if a business account, a team admin. A successful
 * response indicates that the file has been unlocked. Returns a list of the
 * unlocked file paths and their metadata after this operation.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUnlockFileBatch
 * @arg {FilesUnlockFileBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesLockFileBatchResult>, DropboxResponseError.<FilesLockFileError>>}
 */
routes.filesUnlockFileBatch = function (arg) {
  return this.request('files/unlock_file_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Create a new file with the contents provided in the request. Do not use this
 * to upload a file larger than 150 MB. Instead, create an upload session with
 * upload_session/start. Calls to this endpoint will count as data transport
 * calls for any Dropbox Business teams with a limit on the number of data
 * transport calls allowed per month. For more information, see the Data
 * transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUpload
 * @arg {FilesUploadArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesUploadError>>}
 */
routes.filesUpload = function (arg) {
  return this.request('files/upload', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * Append more data to an upload session. When the parameter close is set, this
 * call will close the session. A single request should not upload more than 150
 * MB. The maximum size of a file one can upload to an upload session is 350 GB.
 * Calls to this endpoint will count as data transport calls for any Dropbox
 * Business teams with a limit on the number of data transport calls allowed per
 * month. For more information, see the Data transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionAppendV2
 * @arg {FilesUploadSessionAppendArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilesUploadSessionAppendError>>}
 */
routes.filesUploadSessionAppendV2 = function (arg) {
  return this.request('files/upload_session/append_v2', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * Append more data to an upload session. A single request should not upload
 * more than 150 MB. The maximum size of a file one can upload to an upload
 * session is 350 GB. Calls to this endpoint will count as data transport calls
 * for any Dropbox Business teams with a limit on the number of data transport
 * calls allowed per month. For more information, see the Data transport limit
 * page https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionAppend
 * @deprecated
 * @arg {FilesUploadSessionCursor} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<FilesUploadSessionAppendError>>}
 */
routes.filesUploadSessionAppend = function (arg) {
  return this.request('files/upload_session/append', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * Finish an upload session and save the uploaded data to the given file path. A
 * single request should not upload more than 150 MB. The maximum size of a file
 * one can upload to an upload session is 350 GB. Calls to this endpoint will
 * count as data transport calls for any Dropbox Business teams with a limit on
 * the number of data transport calls allowed per month. For more information,
 * see the Data transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionFinish
 * @arg {FilesUploadSessionFinishArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesFileMetadata>, DropboxResponseError.<FilesUploadSessionFinishError>>}
 */
routes.filesUploadSessionFinish = function (arg) {
  return this.request('files/upload_session/finish', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * This route helps you commit many files at once into a user's Dropbox. Use
 * upload_session/start and upload_session/append_v2 to upload file contents. We
 * recommend uploading many files in parallel to increase throughput. Once the
 * file contents have been uploaded, rather than calling upload_session/finish,
 * use this route to finish all your upload sessions in a single request.
 * UploadSessionStartArg.close or UploadSessionAppendArg.close needs to be true
 * for the last upload_session/start or upload_session/append_v2 call. The
 * maximum size of a file one can upload to an upload session is 350 GB. This
 * route will return a job_id immediately and do the async commit job in
 * background. Use upload_session/finish_batch/check to check the job status.
 * For the same account, this route should be executed serially. That means you
 * should not start the next job before current job finishes. We allow up to
 * 1000 entries in a single request. Calls to this endpoint will count as data
 * transport calls for any Dropbox Business teams with a limit on the number of
 * data transport calls allowed per month. For more information, see the Data
 * transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionFinishBatch
 * @deprecated
 * @arg {FilesUploadSessionFinishBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesUploadSessionFinishBatchLaunch>, DropboxResponseError.<void>>}
 */
routes.filesUploadSessionFinishBatch = function (arg) {
  return this.request('files/upload_session/finish_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * This route helps you commit many files at once into a user's Dropbox. Use
 * upload_session/start and upload_session/append_v2 to upload file contents. We
 * recommend uploading many files in parallel to increase throughput. Once the
 * file contents have been uploaded, rather than calling upload_session/finish,
 * use this route to finish all your upload sessions in a single request.
 * UploadSessionStartArg.close or UploadSessionAppendArg.close needs to be true
 * for the last upload_session/start or upload_session/append_v2 call of each
 * upload session. The maximum size of a file one can upload to an upload
 * session is 350 GB. We allow up to 1000 entries in a single request. Calls to
 * this endpoint will count as data transport calls for any Dropbox Business
 * teams with a limit on the number of data transport calls allowed per month.
 * For more information, see the Data transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionFinishBatchV2
 * @arg {FilesUploadSessionFinishBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesUploadSessionFinishBatchResult>, DropboxResponseError.<void>>}
 */
routes.filesUploadSessionFinishBatchV2 = function (arg) {
  return this.request('files/upload_session/finish_batch_v2', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Returns the status of an asynchronous job for upload_session/finish_batch. If
 * success, it returns list of result for each entry.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionFinishBatchCheck
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesUploadSessionFinishBatchJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.filesUploadSessionFinishBatchCheck = function (arg) {
  return this.request('files/upload_session/finish_batch/check', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Upload sessions allow you to upload a single file in one or more requests,
 * for example where the size of the file is greater than 150 MB.  This call
 * starts a new upload session with the given data. You can then use
 * upload_session/append_v2 to add more data and upload_session/finish to save
 * all the data to a file in Dropbox. A single request should not upload more
 * than 150 MB. The maximum size of a file one can upload to an upload session
 * is 350 GB. An upload session can be used for a maximum of 7 days. Attempting
 * to use an UploadSessionStartResult.session_id with upload_session/append_v2
 * or upload_session/finish more than 7 days after its creation will return a
 * UploadSessionLookupError.not_found. Calls to this endpoint will count as data
 * transport calls for any Dropbox Business teams with a limit on the number of
 * data transport calls allowed per month. For more information, see the Data
 * transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit. By
 * default, upload sessions require you to send content of the file in
 * sequential order via consecutive upload_session/start,
 * upload_session/append_v2, upload_session/finish calls. For better
 * performance, you can instead optionally use a UploadSessionType.concurrent
 * upload session. To start a new concurrent session, set
 * UploadSessionStartArg.session_type to UploadSessionType.concurrent. After
 * that, you can send file data in concurrent upload_session/append_v2 requests.
 * Finally finish the session with upload_session/finish. There are couple of
 * constraints with concurrent sessions to make them work. You can not send data
 * with upload_session/start or upload_session/finish call, only with
 * upload_session/append_v2 call. Also data uploaded in upload_session/append_v2
 * call must be multiple of 4194304 bytes (except for last
 * upload_session/append_v2 with UploadSessionStartArg.close to true, that may
 * contain any remaining data).
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionStart
 * @arg {FilesUploadSessionStartArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesUploadSessionStartResult>, DropboxResponseError.<FilesUploadSessionStartError>>}
 */
routes.filesUploadSessionStart = function (arg) {
  return this.request('files/upload_session/start', arg, 'user', 'content', 'upload', 'files.content.write');
};

/**
 * This route starts batch of upload_sessions. Please refer to
 * `upload_session/start` usage. Calls to this endpoint will count as data
 * transport calls for any Dropbox Business teams with a limit on the number of
 * data transport calls allowed per month. For more information, see the Data
 * transport limit page
 * https://www.dropbox.com/developers/reference/data-transport-limit.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#filesUploadSessionStartBatch
 * @arg {FilesUploadSessionStartBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilesUploadSessionStartBatchResult>, DropboxResponseError.<void>>}
 */
routes.filesUploadSessionStartBatch = function (arg) {
  return this.request('files/upload_session/start_batch', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * This route is used for refreshing the info that is found in the id_token
 * during the OIDC flow. This route doesn't require any arguments and will use
 * the scopes approved for the given access token.
 * Route attributes:
 *   scope: openid
 * @function Dropbox#openidUserinfo
 * @arg {OpenidUserInfoArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<OpenidUserInfoResult>, DropboxResponseError.<OpenidUserInfoError>>}
 */
routes.openidUserinfo = function (arg) {
  return this.request('openid/userinfo', arg, 'user', 'api', 'rpc', 'openid');
};

/**
 * Marks the given Paper doc as archived. This action can be performed or undone
 * by anyone with edit permissions to the doc. Note that this endpoint will
 * continue to work for content created by users on the older version of Paper.
 * To check which version of Paper a user is on, use /users/features/get_values.
 * If the paper_as_files feature is enabled, then the user is running the new
 * version of Paper. This endpoint will be retired in September 2020. Refer to
 * the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * more information.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#paperDocsArchive
 * @deprecated
 * @arg {PaperRefPaperDoc} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsArchive = function (arg) {
  return this.request('paper/docs/archive', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Creates a new Paper doc with the provided content. Note that this endpoint
 * will continue to work for content created by users on the older version of
 * Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. This endpoint will be retired
 * in September 2020. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * more information.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#paperDocsCreate
 * @deprecated
 * @arg {PaperPaperDocCreateArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperPaperDocCreateUpdateResult>, DropboxResponseError.<PaperPaperDocCreateError>>}
 */
routes.paperDocsCreate = function (arg) {
  return this.request('paper/docs/create', arg, 'user', 'api', 'upload', 'files.content.write');
};

/**
 * Exports and downloads Paper doc either as HTML or markdown. Note that this
 * endpoint will continue to work for content created by users on the older
 * version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: files.content.read
 * @function Dropbox#paperDocsDownload
 * @deprecated
 * @arg {PaperPaperDocExport} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperPaperDocExportResult>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsDownload = function (arg) {
  return this.request('paper/docs/download', arg, 'user', 'api', 'download', 'files.content.read');
};

/**
 * Lists the users who are explicitly invited to the Paper folder in which the
 * Paper doc is contained. For private folders all users (including owner)
 * shared on the folder are listed and for team folders all non-team users
 * shared on the folder are returned. Note that this endpoint will continue to
 * work for content created by users on the older version of Paper. To check
 * which version of Paper a user is on, use /users/features/get_values. If the
 * paper_as_files feature is enabled, then the user is running the new version
 * of Paper. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsFolderUsersList
 * @deprecated
 * @arg {PaperListUsersOnFolderArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListUsersOnFolderResponse>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsFolderUsersList = function (arg) {
  return this.request('paper/docs/folder_users/list', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from docs/folder_users/list, use this to
 * paginate through all users on the Paper folder. Note that this endpoint will
 * continue to work for content created by users on the older version of Paper.
 * To check which version of Paper a user is on, use /users/features/get_values.
 * If the paper_as_files feature is enabled, then the user is running the new
 * version of Paper. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsFolderUsersListContinue
 * @deprecated
 * @arg {PaperListUsersOnFolderContinueArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListUsersOnFolderResponse>, DropboxResponseError.<PaperListUsersCursorError>>}
 */
routes.paperDocsFolderUsersListContinue = function (arg) {
  return this.request('paper/docs/folder_users/list/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Retrieves folder information for the given Paper doc. This includes:   -
 * folder sharing policy; permissions for subfolders are set by the top-level
 * folder.   - full 'filepath', i.e. the list of folders (both folderId and
 * folderName) from     the root folder to the folder directly containing the
 * Paper doc.  If the Paper doc is not in any folder (aka unfiled) the response
 * will be empty. Note that this endpoint will continue to work for content
 * created by users on the older version of Paper. To check which version of
 * Paper a user is on, use /users/features/get_values. If the paper_as_files
 * feature is enabled, then the user is running the new version of Paper. Refer
 * to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsGetFolderInfo
 * @deprecated
 * @arg {PaperRefPaperDoc} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperFoldersContainingPaperDoc>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsGetFolderInfo = function (arg) {
  return this.request('paper/docs/get_folder_info', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Return the list of all Paper docs according to the argument specifications.
 * To iterate over through the full pagination, pass the cursor to
 * docs/list/continue. Note that this endpoint will continue to work for content
 * created by users on the older version of Paper. To check which version of
 * Paper a user is on, use /users/features/get_values. If the paper_as_files
 * feature is enabled, then the user is running the new version of Paper. Refer
 * to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#paperDocsList
 * @deprecated
 * @arg {PaperListPaperDocsArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListPaperDocsResponse>, DropboxResponseError.<void>>}
 */
routes.paperDocsList = function (arg) {
  return this.request('paper/docs/list', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Once a cursor has been retrieved from docs/list, use this to paginate through
 * all Paper doc. Note that this endpoint will continue to work for content
 * created by users on the older version of Paper. To check which version of
 * Paper a user is on, use /users/features/get_values. If the paper_as_files
 * feature is enabled, then the user is running the new version of Paper. Refer
 * to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: files.metadata.read
 * @function Dropbox#paperDocsListContinue
 * @deprecated
 * @arg {PaperListPaperDocsContinueArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListPaperDocsResponse>, DropboxResponseError.<PaperListDocsCursorError>>}
 */
routes.paperDocsListContinue = function (arg) {
  return this.request('paper/docs/list/continue', arg, 'user', 'api', 'rpc', 'files.metadata.read');
};

/**
 * Permanently deletes the given Paper doc. This operation is final as the doc
 * cannot be recovered. This action can be performed only by the doc owner. Note
 * that this endpoint will continue to work for content created by users on the
 * older version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: files.permanent_delete
 * @function Dropbox#paperDocsPermanentlyDelete
 * @deprecated
 * @arg {PaperRefPaperDoc} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsPermanentlyDelete = function (arg) {
  return this.request('paper/docs/permanently_delete', arg, 'user', 'api', 'rpc', 'files.permanent_delete');
};

/**
 * Gets the default sharing policy for the given Paper doc. Note that this
 * endpoint will continue to work for content created by users on the older
 * version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsSharingPolicyGet
 * @deprecated
 * @arg {PaperRefPaperDoc} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperSharingPolicy>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsSharingPolicyGet = function (arg) {
  return this.request('paper/docs/sharing_policy/get', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Sets the default sharing policy for the given Paper doc. The default
 * 'team_sharing_policy' can be changed only by teams, omit this field for
 * personal accounts. The 'public_sharing_policy' policy can't be set to the
 * value 'disabled' because this setting can be changed only via the team admin
 * console. Note that this endpoint will continue to work for content created by
 * users on the older version of Paper. To check which version of Paper a user
 * is on, use /users/features/get_values. If the paper_as_files feature is
 * enabled, then the user is running the new version of Paper. Refer to the
 * Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#paperDocsSharingPolicySet
 * @deprecated
 * @arg {PaperPaperDocSharingPolicy} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsSharingPolicySet = function (arg) {
  return this.request('paper/docs/sharing_policy/set', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Updates an existing Paper doc with the provided content. Note that this
 * endpoint will continue to work for content created by users on the older
 * version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. This endpoint will be retired
 * in September 2020. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * more information.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#paperDocsUpdate
 * @deprecated
 * @arg {PaperPaperDocUpdateArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperPaperDocCreateUpdateResult>, DropboxResponseError.<PaperPaperDocUpdateError>>}
 */
routes.paperDocsUpdate = function (arg) {
  return this.request('paper/docs/update', arg, 'user', 'api', 'upload', 'files.content.write');
};

/**
 * Allows an owner or editor to add users to a Paper doc or change their
 * permissions using their email address or Dropbox account ID. The doc owner's
 * permissions cannot be changed. Note that this endpoint will continue to work
 * for content created by users on the older version of Paper. To check which
 * version of Paper a user is on, use /users/features/get_values. If the
 * paper_as_files feature is enabled, then the user is running the new version
 * of Paper. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#paperDocsUsersAdd
 * @deprecated
 * @arg {PaperAddPaperDocUser} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<PaperAddPaperDocUserMemberResult>>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsUsersAdd = function (arg) {
  return this.request('paper/docs/users/add', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Lists all users who visited the Paper doc or users with explicit access. This
 * call excludes users who have been removed. The list is sorted by the date of
 * the visit or the share date. The list will include both users, the explicitly
 * shared ones as well as those who came in using the Paper url link. Note that
 * this endpoint will continue to work for content created by users on the older
 * version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsUsersList
 * @deprecated
 * @arg {PaperListUsersOnPaperDocArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListUsersOnPaperDocResponse>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsUsersList = function (arg) {
  return this.request('paper/docs/users/list', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from docs/users/list, use this to paginate
 * through all users on the Paper doc. Note that this endpoint will continue to
 * work for content created by users on the older version of Paper. To check
 * which version of Paper a user is on, use /users/features/get_values. If the
 * paper_as_files feature is enabled, then the user is running the new version
 * of Paper. Refer to the Paper Migration Guide
 * https://www.dropbox.com/lp/developers/reference/paper-migration-guide for
 * migration information.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#paperDocsUsersListContinue
 * @deprecated
 * @arg {PaperListUsersOnPaperDocContinueArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperListUsersOnPaperDocResponse>, DropboxResponseError.<PaperListUsersCursorError>>}
 */
routes.paperDocsUsersListContinue = function (arg) {
  return this.request('paper/docs/users/list/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Allows an owner or editor to remove users from a Paper doc using their email
 * address or Dropbox account ID. The doc owner cannot be removed. Note that
 * this endpoint will continue to work for content created by users on the older
 * version of Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#paperDocsUsersRemove
 * @deprecated
 * @arg {PaperRemovePaperDocUser} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<PaperDocLookupError>>}
 */
routes.paperDocsUsersRemove = function (arg) {
  return this.request('paper/docs/users/remove', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Create a new Paper folder with the provided info. Note that this endpoint
 * will continue to work for content created by users on the older version of
 * Paper. To check which version of Paper a user is on, use
 * /users/features/get_values. If the paper_as_files feature is enabled, then
 * the user is running the new version of Paper. Refer to the Paper Migration
 * Guide https://www.dropbox.com/lp/developers/reference/paper-migration-guide
 * for migration information.
 * Route attributes:
 *   scope: files.content.write
 * @function Dropbox#paperFoldersCreate
 * @deprecated
 * @arg {PaperPaperFolderCreateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<PaperPaperFolderCreateResult>, DropboxResponseError.<PaperPaperFolderCreateError>>}
 */
routes.paperFoldersCreate = function (arg) {
  return this.request('paper/folders/create', arg, 'user', 'api', 'rpc', 'files.content.write');
};

/**
 * Adds specified members to a file.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingAddFileMember
 * @arg {SharingAddFileMemberArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<SharingFileMemberActionResult>>, DropboxResponseError.<SharingAddFileMemberError>>}
 */
routes.sharingAddFileMember = function (arg) {
  return this.request('sharing/add_file_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Allows an owner or editor (if the ACL update policy allows) of a shared
 * folder to add another member. For the new member to get access to all the
 * functionality for this folder, you will need to call mount_folder on their
 * behalf.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingAddFolderMember
 * @arg {SharingAddFolderMemberArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingAddFolderMemberError>>}
 */
routes.sharingAddFolderMember = function (arg) {
  return this.request('sharing/add_folder_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Returns the status of an asynchronous job.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingCheckJobStatus
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.sharingCheckJobStatus = function (arg) {
  return this.request('sharing/check_job_status', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Returns the status of an asynchronous job for sharing a folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingCheckRemoveMemberJobStatus
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingRemoveMemberJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.sharingCheckRemoveMemberJobStatus = function (arg) {
  return this.request('sharing/check_remove_member_job_status', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Returns the status of an asynchronous job for sharing a folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingCheckShareJobStatus
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingShareFolderJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.sharingCheckShareJobStatus = function (arg) {
  return this.request('sharing/check_share_job_status', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Create a shared link. If a shared link already exists for the given path,
 * that link is returned. Previously, it was technically possible to break a
 * shared link by moving or renaming the corresponding file or folder. In the
 * future, this will no longer be the case, so your app shouldn't rely on this
 * behavior. Instead, if your app needs to revoke a shared link, use
 * revoke_shared_link.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingCreateSharedLink
 * @deprecated
 * @arg {SharingCreateSharedLinkArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingPathLinkMetadata>, DropboxResponseError.<SharingCreateSharedLinkError>>}
 */
routes.sharingCreateSharedLink = function (arg) {
  return this.request('sharing/create_shared_link', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Create a shared link with custom settings. If no settings are given then the
 * default visibility is RequestedVisibility.public (The resolved visibility,
 * though, may depend on other aspects such as team and shared folder settings).
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingCreateSharedLinkWithSettings
 * @arg {SharingCreateSharedLinkWithSettingsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(SharingFileLinkMetadata|SharingFolderLinkMetadata|SharingSharedLinkMetadata)>, DropboxResponseError.<SharingCreateSharedLinkWithSettingsError>>}
 */
routes.sharingCreateSharedLinkWithSettings = function (arg) {
  return this.request('sharing/create_shared_link_with_settings', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Returns shared file metadata.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetFileMetadata
 * @arg {SharingGetFileMetadataArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFileMetadata>, DropboxResponseError.<SharingGetFileMetadataError>>}
 */
routes.sharingGetFileMetadata = function (arg) {
  return this.request('sharing/get_file_metadata', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Returns shared file metadata.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetFileMetadataBatch
 * @arg {SharingGetFileMetadataBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<SharingGetFileMetadataBatchResult>>, DropboxResponseError.<SharingSharingUserError>>}
 */
routes.sharingGetFileMetadataBatch = function (arg) {
  return this.request('sharing/get_file_metadata/batch', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Returns shared folder metadata by its folder ID.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetFolderMetadata
 * @arg {SharingGetMetadataArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFolderMetadata>, DropboxResponseError.<SharingSharedFolderAccessError>>}
 */
routes.sharingGetFolderMetadata = function (arg) {
  return this.request('sharing/get_folder_metadata', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Download the shared link's file from a user's Dropbox.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetSharedLinkFile
 * @arg {Object} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(SharingFileLinkMetadata|SharingFolderLinkMetadata|SharingSharedLinkMetadata)>, DropboxResponseError.<SharingGetSharedLinkFileError>>}
 */
routes.sharingGetSharedLinkFile = function (arg) {
  return this.request('sharing/get_shared_link_file', arg, 'user', 'content', 'download', 'sharing.read');
};

/**
 * Get the shared link's metadata.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetSharedLinkMetadata
 * @arg {SharingGetSharedLinkMetadataArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(SharingFileLinkMetadata|SharingFolderLinkMetadata|SharingSharedLinkMetadata)>, DropboxResponseError.<SharingSharedLinkError>>}
 */
routes.sharingGetSharedLinkMetadata = function (arg) {
  return this.request('sharing/get_shared_link_metadata', arg, 'app, user', 'api', 'rpc', 'sharing.read');
};

/**
 * Returns a list of LinkMetadata objects for this user, including collection
 * links. If no path is given, returns a list of all shared links for the
 * current user, including collection links, up to a maximum of 1000 links. If a
 * non-empty path is given, returns a list of all shared links that allow access
 * to the given path.  Collection links are never returned in this case.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingGetSharedLinks
 * @deprecated
 * @arg {SharingGetSharedLinksArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingGetSharedLinksResult>, DropboxResponseError.<SharingGetSharedLinksError>>}
 */
routes.sharingGetSharedLinks = function (arg) {
  return this.request('sharing/get_shared_links', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Use to obtain the members who have been invited to a file, both inherited and
 * uninherited members.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFileMembers
 * @arg {SharingListFileMembersArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFileMembers>, DropboxResponseError.<SharingListFileMembersError>>}
 */
routes.sharingListFileMembers = function (arg) {
  return this.request('sharing/list_file_members', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Get members of multiple files at once. The arguments to this route are more
 * limited, and the limit on query result size per file is more strict. To
 * customize the results more, use the individual file endpoint. Inherited users
 * and groups are not included in the result, and permissions are not returned
 * for this endpoint.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFileMembersBatch
 * @arg {SharingListFileMembersBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<SharingListFileMembersBatchResult>>, DropboxResponseError.<SharingSharingUserError>>}
 */
routes.sharingListFileMembersBatch = function (arg) {
  return this.request('sharing/list_file_members/batch', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from list_file_members or
 * list_file_members/batch, use this to paginate through all shared file
 * members.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFileMembersContinue
 * @arg {SharingListFileMembersContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFileMembers>, DropboxResponseError.<SharingListFileMembersContinueError>>}
 */
routes.sharingListFileMembersContinue = function (arg) {
  return this.request('sharing/list_file_members/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Returns shared folder membership by its folder ID.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFolderMembers
 * @arg {SharingListFolderMembersArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFolderMembers>, DropboxResponseError.<SharingSharedFolderAccessError>>}
 */
routes.sharingListFolderMembers = function (arg) {
  return this.request('sharing/list_folder_members', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from list_folder_members, use this to
 * paginate through all shared folder members.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFolderMembersContinue
 * @arg {SharingListFolderMembersContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFolderMembers>, DropboxResponseError.<SharingListFolderMembersContinueError>>}
 */
routes.sharingListFolderMembersContinue = function (arg) {
  return this.request('sharing/list_folder_members/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Return the list of all shared folders the current user has access to.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFolders
 * @arg {SharingListFoldersArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFoldersResult>, DropboxResponseError.<void>>}
 */
routes.sharingListFolders = function (arg) {
  return this.request('sharing/list_folders', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from list_folders, use this to paginate
 * through all shared folders. The cursor must come from a previous call to
 * list_folders or list_folders/continue.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListFoldersContinue
 * @arg {SharingListFoldersContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFoldersResult>, DropboxResponseError.<SharingListFoldersContinueError>>}
 */
routes.sharingListFoldersContinue = function (arg) {
  return this.request('sharing/list_folders/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Return the list of all shared folders the current user can mount or unmount.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListMountableFolders
 * @arg {SharingListFoldersArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFoldersResult>, DropboxResponseError.<void>>}
 */
routes.sharingListMountableFolders = function (arg) {
  return this.request('sharing/list_mountable_folders', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Once a cursor has been retrieved from list_mountable_folders, use this to
 * paginate through all mountable shared folders. The cursor must come from a
 * previous call to list_mountable_folders or list_mountable_folders/continue.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListMountableFoldersContinue
 * @arg {SharingListFoldersContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFoldersResult>, DropboxResponseError.<SharingListFoldersContinueError>>}
 */
routes.sharingListMountableFoldersContinue = function (arg) {
  return this.request('sharing/list_mountable_folders/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Returns a list of all files shared with current user.  Does not include files
 * the user has received via shared folders, and does  not include unclaimed
 * invitations.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListReceivedFiles
 * @arg {SharingListFilesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFilesResult>, DropboxResponseError.<SharingSharingUserError>>}
 */
routes.sharingListReceivedFiles = function (arg) {
  return this.request('sharing/list_received_files', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Get more results with a cursor from list_received_files.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListReceivedFilesContinue
 * @arg {SharingListFilesContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListFilesResult>, DropboxResponseError.<SharingListFilesContinueError>>}
 */
routes.sharingListReceivedFilesContinue = function (arg) {
  return this.request('sharing/list_received_files/continue', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * List shared links of this user. If no path is given, returns a list of all
 * shared links for the current user. For members of business teams using team
 * space and member folders, returns all shared links in the team member's home
 * folder unless the team space ID is specified in the request header. For more
 * information, refer to the Namespace Guide
 * https://www.dropbox.com/developers/reference/namespace-guide. If a non-empty
 * path is given, returns a list of all shared links that allow access to the
 * given path - direct links to the given path and links to parent folders of
 * the given path. Links to parent folders can be suppressed by setting
 * direct_only to true.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#sharingListSharedLinks
 * @arg {SharingListSharedLinksArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingListSharedLinksResult>, DropboxResponseError.<SharingListSharedLinksError>>}
 */
routes.sharingListSharedLinks = function (arg) {
  return this.request('sharing/list_shared_links', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Modify the shared link's settings. If the requested visibility conflict with
 * the shared links policy of the team or the shared folder (in case the linked
 * file is part of a shared folder) then the LinkPermissions.resolved_visibility
 * of the returned SharedLinkMetadata will reflect the actual visibility of the
 * shared link and the LinkPermissions.requested_visibility will reflect the
 * requested visibility.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingModifySharedLinkSettings
 * @arg {SharingModifySharedLinkSettingsArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<(SharingFileLinkMetadata|SharingFolderLinkMetadata|SharingSharedLinkMetadata)>, DropboxResponseError.<SharingModifySharedLinkSettingsError>>}
 */
routes.sharingModifySharedLinkSettings = function (arg) {
  return this.request('sharing/modify_shared_link_settings', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * The current user mounts the designated folder. Mount a shared folder for a
 * user after they have been added as a member. Once mounted, the shared folder
 * will appear in their Dropbox.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingMountFolder
 * @arg {SharingMountFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFolderMetadata>, DropboxResponseError.<SharingMountFolderError>>}
 */
routes.sharingMountFolder = function (arg) {
  return this.request('sharing/mount_folder', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * The current user relinquishes their membership in the designated file. Note
 * that the current user may still have inherited access to this file through
 * the parent folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRelinquishFileMembership
 * @arg {SharingRelinquishFileMembershipArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingRelinquishFileMembershipError>>}
 */
routes.sharingRelinquishFileMembership = function (arg) {
  return this.request('sharing/relinquish_file_membership', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * The current user relinquishes their membership in the designated shared
 * folder and will no longer have access to the folder.  A folder owner cannot
 * relinquish membership in their own folder. This will run synchronously if
 * leave_a_copy is false, and asynchronously if leave_a_copy is true.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRelinquishFolderMembership
 * @arg {SharingRelinquishFolderMembershipArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchEmptyResult>, DropboxResponseError.<SharingRelinquishFolderMembershipError>>}
 */
routes.sharingRelinquishFolderMembership = function (arg) {
  return this.request('sharing/relinquish_folder_membership', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Identical to remove_file_member_2 but with less information returned.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRemoveFileMember
 * @deprecated
 * @arg {SharingRemoveFileMemberArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingFileMemberActionIndividualResult>, DropboxResponseError.<SharingRemoveFileMemberError>>}
 */
routes.sharingRemoveFileMember = function (arg) {
  return this.request('sharing/remove_file_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Removes a specified member from the file.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRemoveFileMember2
 * @arg {SharingRemoveFileMemberArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingFileMemberRemoveActionResult>, DropboxResponseError.<SharingRemoveFileMemberError>>}
 */
routes.sharingRemoveFileMember2 = function (arg) {
  return this.request('sharing/remove_file_member_2', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Allows an owner or editor (if the ACL update policy allows) of a shared
 * folder to remove another member.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRemoveFolderMember
 * @arg {SharingRemoveFolderMemberArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchResultBase>, DropboxResponseError.<SharingRemoveFolderMemberError>>}
 */
routes.sharingRemoveFolderMember = function (arg) {
  return this.request('sharing/remove_folder_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Revoke a shared link. Note that even after revoking a shared link to a file,
 * the file may be accessible if there are shared links leading to any of the
 * file parent folders. To list all shared links that enable access to a
 * specific file, you can use the list_shared_links with the file as the
 * ListSharedLinksArg.path argument.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingRevokeSharedLink
 * @arg {SharingRevokeSharedLinkArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingRevokeSharedLinkError>>}
 */
routes.sharingRevokeSharedLink = function (arg) {
  return this.request('sharing/revoke_shared_link', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Change the inheritance policy of an existing Shared Folder. Only permitted
 * for shared folders in a shared team root. If a ShareFolderLaunch.async_job_id
 * is returned, you'll need to call check_share_job_status until the action
 * completes to get the metadata for the folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingSetAccessInheritance
 * @arg {SharingSetAccessInheritanceArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingShareFolderLaunch>, DropboxResponseError.<SharingSetAccessInheritanceError>>}
 */
routes.sharingSetAccessInheritance = function (arg) {
  return this.request('sharing/set_access_inheritance', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Share a folder with collaborators. Most sharing will be completed
 * synchronously. Large folders will be completed asynchronously. To make
 * testing the async case repeatable, set `ShareFolderArg.force_async`. If a
 * ShareFolderLaunch.async_job_id is returned, you'll need to call
 * check_share_job_status until the action completes to get the metadata for the
 * folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingShareFolder
 * @arg {SharingShareFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingShareFolderLaunch>, DropboxResponseError.<SharingShareFolderError>>}
 */
routes.sharingShareFolder = function (arg) {
  return this.request('sharing/share_folder', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Transfer ownership of a shared folder to a member of the shared folder. User
 * must have AccessLevel.owner access to the shared folder to perform a
 * transfer.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingTransferFolder
 * @arg {SharingTransferFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingTransferFolderError>>}
 */
routes.sharingTransferFolder = function (arg) {
  return this.request('sharing/transfer_folder', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * The current user unmounts the designated folder. They can re-mount the folder
 * at a later time using mount_folder.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUnmountFolder
 * @arg {SharingUnmountFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingUnmountFolderError>>}
 */
routes.sharingUnmountFolder = function (arg) {
  return this.request('sharing/unmount_folder', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Remove all members from this file. Does not remove inherited members.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUnshareFile
 * @arg {SharingUnshareFileArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<SharingUnshareFileError>>}
 */
routes.sharingUnshareFile = function (arg) {
  return this.request('sharing/unshare_file', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Allows a shared folder owner to unshare the folder. You'll need to call
 * check_job_status to determine if the action has completed successfully.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUnshareFolder
 * @arg {SharingUnshareFolderArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchEmptyResult>, DropboxResponseError.<SharingUnshareFolderError>>}
 */
routes.sharingUnshareFolder = function (arg) {
  return this.request('sharing/unshare_folder', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Changes a member's access on a shared file.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUpdateFileMember
 * @arg {SharingUpdateFileMemberArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingMemberAccessLevelResult>, DropboxResponseError.<SharingFileMemberActionError>>}
 */
routes.sharingUpdateFileMember = function (arg) {
  return this.request('sharing/update_file_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Allows an owner or editor of a shared folder to update another member's
 * permissions.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUpdateFolderMember
 * @arg {SharingUpdateFolderMemberArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingMemberAccessLevelResult>, DropboxResponseError.<SharingUpdateFolderMemberError>>}
 */
routes.sharingUpdateFolderMember = function (arg) {
  return this.request('sharing/update_folder_member', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * Update the sharing policies for a shared folder. User must have
 * AccessLevel.owner access to the shared folder to update its policies.
 * Route attributes:
 *   scope: sharing.write
 * @function Dropbox#sharingUpdateFolderPolicy
 * @arg {SharingUpdateFolderPolicyArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<SharingSharedFolderMetadata>, DropboxResponseError.<SharingUpdateFolderPolicyError>>}
 */
routes.sharingUpdateFolderPolicy = function (arg) {
  return this.request('sharing/update_folder_policy', arg, 'user', 'api', 'rpc', 'sharing.write');
};

/**
 * List all device sessions of a team's member.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamDevicesListMemberDevices
 * @arg {TeamListMemberDevicesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListMemberDevicesResult>, DropboxResponseError.<TeamListMemberDevicesError>>}
 */
routes.teamDevicesListMemberDevices = function (arg) {
  return this.request('team/devices/list_member_devices', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * List all device sessions of a team. Permission : Team member file access.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamDevicesListMembersDevices
 * @arg {TeamListMembersDevicesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListMembersDevicesResult>, DropboxResponseError.<TeamListMembersDevicesError>>}
 */
routes.teamDevicesListMembersDevices = function (arg) {
  return this.request('team/devices/list_members_devices', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * List all device sessions of a team. Permission : Team member file access.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamDevicesListTeamDevices
 * @deprecated
 * @arg {TeamListTeamDevicesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListTeamDevicesResult>, DropboxResponseError.<TeamListTeamDevicesError>>}
 */
routes.teamDevicesListTeamDevices = function (arg) {
  return this.request('team/devices/list_team_devices', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * Revoke a device session of a team's member.
 * Route attributes:
 *   scope: sessions.modify
 * @function Dropbox#teamDevicesRevokeDeviceSession
 * @arg {TeamRevokeDeviceSessionArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamRevokeDeviceSessionError>>}
 */
routes.teamDevicesRevokeDeviceSession = function (arg) {
  return this.request('team/devices/revoke_device_session', arg, 'team', 'api', 'rpc', 'sessions.modify');
};

/**
 * Revoke a list of device sessions of team members.
 * Route attributes:
 *   scope: sessions.modify
 * @function Dropbox#teamDevicesRevokeDeviceSessionBatch
 * @arg {TeamRevokeDeviceSessionBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamRevokeDeviceSessionBatchResult>, DropboxResponseError.<TeamRevokeDeviceSessionBatchError>>}
 */
routes.teamDevicesRevokeDeviceSessionBatch = function (arg) {
  return this.request('team/devices/revoke_device_session_batch', arg, 'team', 'api', 'rpc', 'sessions.modify');
};

/**
 * Get the values for one or more featues. This route allows you to check your
 * account's capability for what feature you can access or what value you have
 * for certain features. Permission : Team information.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamFeaturesGetValues
 * @arg {TeamFeaturesGetValuesBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamFeaturesGetValuesBatchResult>, DropboxResponseError.<TeamFeaturesGetValuesBatchError>>}
 */
routes.teamFeaturesGetValues = function (arg) {
  return this.request('team/features/get_values', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Retrieves information about a team.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamGetInfo
 * @returns {Promise.<DropboxResponse<TeamTeamGetInfoResult>, DropboxResponseError.<void>>}
 */
routes.teamGetInfo = function () {
  return this.request('team/get_info', null, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Creates a new, empty group, with a requested name. Permission : Team member
 * management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsCreate
 * @arg {TeamGroupCreateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupFullInfo>, DropboxResponseError.<TeamGroupCreateError>>}
 */
routes.teamGroupsCreate = function (arg) {
  return this.request('team/groups/create', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Deletes a group. The group is deleted immediately. However the revoking of
 * group-owned resources may take additional time. Use the groups/job_status/get
 * to determine whether this process has completed. Permission : Team member
 * management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsDelete
 * @arg {TeamGroupSelector} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchEmptyResult>, DropboxResponseError.<TeamGroupDeleteError>>}
 */
routes.teamGroupsDelete = function (arg) {
  return this.request('team/groups/delete', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Retrieves information about one or more groups. Note that the optional field
 * GroupFullInfo.members is not returned for system-managed groups. Permission :
 * Team Information.
 * Route attributes:
 *   scope: groups.read
 * @function Dropbox#teamGroupsGetInfo
 * @arg {TeamGroupsSelector} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamGroupsGetInfoError>>}
 */
routes.teamGroupsGetInfo = function (arg) {
  return this.request('team/groups/get_info', arg, 'team', 'api', 'rpc', 'groups.read');
};

/**
 * Once an async_job_id is returned from groups/delete, groups/members/add , or
 * groups/members/remove use this method to poll the status of granting/revoking
 * group members' access to group-owned resources. Permission : Team member
 * management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsJobStatusGet
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncPollEmptyResult>, DropboxResponseError.<TeamGroupsPollError>>}
 */
routes.teamGroupsJobStatusGet = function (arg) {
  return this.request('team/groups/job_status/get', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Lists groups on a team. Permission : Team Information.
 * Route attributes:
 *   scope: groups.read
 * @function Dropbox#teamGroupsList
 * @arg {TeamGroupsListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupsListResult>, DropboxResponseError.<void>>}
 */
routes.teamGroupsList = function (arg) {
  return this.request('team/groups/list', arg, 'team', 'api', 'rpc', 'groups.read');
};

/**
 * Once a cursor has been retrieved from groups/list, use this to paginate
 * through all groups. Permission : Team Information.
 * Route attributes:
 *   scope: groups.read
 * @function Dropbox#teamGroupsListContinue
 * @arg {TeamGroupsListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupsListResult>, DropboxResponseError.<TeamGroupsListContinueError>>}
 */
routes.teamGroupsListContinue = function (arg) {
  return this.request('team/groups/list/continue', arg, 'team', 'api', 'rpc', 'groups.read');
};

/**
 * Adds members to a group. The members are added immediately. However the
 * granting of group-owned resources may take additional time. Use the
 * groups/job_status/get to determine whether this process has completed.
 * Permission : Team member management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsMembersAdd
 * @arg {TeamGroupMembersAddArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupMembersChangeResult>, DropboxResponseError.<TeamGroupMembersAddError>>}
 */
routes.teamGroupsMembersAdd = function (arg) {
  return this.request('team/groups/members/add', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Lists members of a group. Permission : Team Information.
 * Route attributes:
 *   scope: groups.read
 * @function Dropbox#teamGroupsMembersList
 * @arg {TeamGroupsMembersListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupsMembersListResult>, DropboxResponseError.<TeamGroupSelectorError>>}
 */
routes.teamGroupsMembersList = function (arg) {
  return this.request('team/groups/members/list', arg, 'team', 'api', 'rpc', 'groups.read');
};

/**
 * Once a cursor has been retrieved from groups/members/list, use this to
 * paginate through all members of the group. Permission : Team information.
 * Route attributes:
 *   scope: groups.read
 * @function Dropbox#teamGroupsMembersListContinue
 * @arg {TeamGroupsMembersListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupsMembersListResult>, DropboxResponseError.<TeamGroupsMembersListContinueError>>}
 */
routes.teamGroupsMembersListContinue = function (arg) {
  return this.request('team/groups/members/list/continue', arg, 'team', 'api', 'rpc', 'groups.read');
};

/**
 * Removes members from a group. The members are removed immediately. However
 * the revoking of group-owned resources may take additional time. Use the
 * groups/job_status/get to determine whether this process has completed. This
 * method permits removing the only owner of a group, even in cases where this
 * is not possible via the web client. Permission : Team member management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsMembersRemove
 * @arg {TeamGroupMembersRemoveArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupMembersChangeResult>, DropboxResponseError.<TeamGroupMembersRemoveError>>}
 */
routes.teamGroupsMembersRemove = function (arg) {
  return this.request('team/groups/members/remove', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Sets a member's access type in a group. Permission : Team member management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsMembersSetAccessType
 * @arg {TeamGroupMembersSetAccessTypeArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamGroupMemberSetAccessTypeError>>}
 */
routes.teamGroupsMembersSetAccessType = function (arg) {
  return this.request('team/groups/members/set_access_type', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Updates a group's name and/or external ID. Permission : Team member
 * management.
 * Route attributes:
 *   scope: groups.write
 * @function Dropbox#teamGroupsUpdate
 * @arg {TeamGroupUpdateArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGroupFullInfo>, DropboxResponseError.<TeamGroupUpdateError>>}
 */
routes.teamGroupsUpdate = function (arg) {
  return this.request('team/groups/update', arg, 'team', 'api', 'rpc', 'groups.write');
};

/**
 * Creates new legal hold policy. Note: Legal Holds is a paid add-on. Not all
 * teams have the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsCreatePolicy
 * @arg {TeamLegalHoldsPolicyCreateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamLegalHoldsPolicyCreateError>>}
 */
routes.teamLegalHoldsCreatePolicy = function (arg) {
  return this.request('team/legal_holds/create_policy', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * Gets a legal hold by Id. Note: Legal Holds is a paid add-on. Not all teams
 * have the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsGetPolicy
 * @arg {TeamLegalHoldsGetPolicyArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamLegalHoldsGetPolicyError>>}
 */
routes.teamLegalHoldsGetPolicy = function (arg) {
  return this.request('team/legal_holds/get_policy', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * List the file metadata that's under the hold. Note: Legal Holds is a paid
 * add-on. Not all teams have the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsListHeldRevisions
 * @arg {TeamLegalHoldsListHeldRevisionsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamLegalHoldsListHeldRevisionResult>, DropboxResponseError.<TeamLegalHoldsListHeldRevisionsError>>}
 */
routes.teamLegalHoldsListHeldRevisions = function (arg) {
  return this.request('team/legal_holds/list_held_revisions', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * Continue listing the file metadata that's under the hold. Note: Legal Holds
 * is a paid add-on. Not all teams have the feature. Permission : Team member
 * file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsListHeldRevisionsContinue
 * @arg {TeamLegalHoldsListHeldRevisionsContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamLegalHoldsListHeldRevisionResult>, DropboxResponseError.<TeamLegalHoldsListHeldRevisionsError>>}
 */
routes.teamLegalHoldsListHeldRevisionsContinue = function (arg) {
  return this.request('team/legal_holds/list_held_revisions_continue', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * Lists legal holds on a team. Note: Legal Holds is a paid add-on. Not all
 * teams have the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsListPolicies
 * @arg {TeamLegalHoldsListPoliciesArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamLegalHoldsListPoliciesResult>, DropboxResponseError.<TeamLegalHoldsListPoliciesError>>}
 */
routes.teamLegalHoldsListPolicies = function (arg) {
  return this.request('team/legal_holds/list_policies', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * Releases a legal hold by Id. Note: Legal Holds is a paid add-on. Not all
 * teams have the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsReleasePolicy
 * @arg {TeamLegalHoldsPolicyReleaseArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamLegalHoldsPolicyReleaseError>>}
 */
routes.teamLegalHoldsReleasePolicy = function (arg) {
  return this.request('team/legal_holds/release_policy', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * Updates a legal hold. Note: Legal Holds is a paid add-on. Not all teams have
 * the feature. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.governance.write
 * @function Dropbox#teamLegalHoldsUpdatePolicy
 * @arg {TeamLegalHoldsPolicyUpdateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamLegalHoldsPolicyUpdateError>>}
 */
routes.teamLegalHoldsUpdatePolicy = function (arg) {
  return this.request('team/legal_holds/update_policy', arg, 'team', 'api', 'rpc', 'team_data.governance.write');
};

/**
 * List all linked applications of the team member. Note, this endpoint does not
 * list any team-linked applications.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamLinkedAppsListMemberLinkedApps
 * @arg {TeamListMemberAppsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListMemberAppsResult>, DropboxResponseError.<TeamListMemberAppsError>>}
 */
routes.teamLinkedAppsListMemberLinkedApps = function (arg) {
  return this.request('team/linked_apps/list_member_linked_apps', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * List all applications linked to the team members' accounts. Note, this
 * endpoint does not list any team-linked applications.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamLinkedAppsListMembersLinkedApps
 * @arg {TeamListMembersAppsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListMembersAppsResult>, DropboxResponseError.<TeamListMembersAppsError>>}
 */
routes.teamLinkedAppsListMembersLinkedApps = function (arg) {
  return this.request('team/linked_apps/list_members_linked_apps', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * List all applications linked to the team members' accounts. Note, this
 * endpoint doesn't list any team-linked applications.
 * Route attributes:
 *   scope: sessions.list
 * @function Dropbox#teamLinkedAppsListTeamLinkedApps
 * @deprecated
 * @arg {TeamListTeamAppsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamListTeamAppsResult>, DropboxResponseError.<TeamListTeamAppsError>>}
 */
routes.teamLinkedAppsListTeamLinkedApps = function (arg) {
  return this.request('team/linked_apps/list_team_linked_apps', arg, 'team', 'api', 'rpc', 'sessions.list');
};

/**
 * Revoke a linked application of the team member.
 * Route attributes:
 *   scope: sessions.modify
 * @function Dropbox#teamLinkedAppsRevokeLinkedApp
 * @arg {TeamRevokeLinkedApiAppArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamRevokeLinkedAppError>>}
 */
routes.teamLinkedAppsRevokeLinkedApp = function (arg) {
  return this.request('team/linked_apps/revoke_linked_app', arg, 'team', 'api', 'rpc', 'sessions.modify');
};

/**
 * Revoke a list of linked applications of the team members.
 * Route attributes:
 *   scope: sessions.modify
 * @function Dropbox#teamLinkedAppsRevokeLinkedAppBatch
 * @arg {TeamRevokeLinkedApiAppBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamRevokeLinkedAppBatchResult>, DropboxResponseError.<TeamRevokeLinkedAppBatchError>>}
 */
routes.teamLinkedAppsRevokeLinkedAppBatch = function (arg) {
  return this.request('team/linked_apps/revoke_linked_app_batch', arg, 'team', 'api', 'rpc', 'sessions.modify');
};

/**
 * Add users to member space limits excluded users list.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMemberSpaceLimitsExcludedUsersAdd
 * @arg {TeamExcludedUsersUpdateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamExcludedUsersUpdateResult>, DropboxResponseError.<TeamExcludedUsersUpdateError>>}
 */
routes.teamMemberSpaceLimitsExcludedUsersAdd = function (arg) {
  return this.request('team/member_space_limits/excluded_users/add', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * List member space limits excluded users.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMemberSpaceLimitsExcludedUsersList
 * @arg {TeamExcludedUsersListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamExcludedUsersListResult>, DropboxResponseError.<TeamExcludedUsersListError>>}
 */
routes.teamMemberSpaceLimitsExcludedUsersList = function (arg) {
  return this.request('team/member_space_limits/excluded_users/list', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Continue listing member space limits excluded users.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMemberSpaceLimitsExcludedUsersListContinue
 * @arg {TeamExcludedUsersListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamExcludedUsersListResult>, DropboxResponseError.<TeamExcludedUsersListContinueError>>}
 */
routes.teamMemberSpaceLimitsExcludedUsersListContinue = function (arg) {
  return this.request('team/member_space_limits/excluded_users/list/continue', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Remove users from member space limits excluded users list.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMemberSpaceLimitsExcludedUsersRemove
 * @arg {TeamExcludedUsersUpdateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamExcludedUsersUpdateResult>, DropboxResponseError.<TeamExcludedUsersUpdateError>>}
 */
routes.teamMemberSpaceLimitsExcludedUsersRemove = function (arg) {
  return this.request('team/member_space_limits/excluded_users/remove', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Get users custom quota. Returns none as the custom quota if none was set. A
 * maximum of 1000 members can be specified in a single call.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMemberSpaceLimitsGetCustomQuota
 * @arg {TeamCustomQuotaUsersArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<TeamCustomQuotaResult>>, DropboxResponseError.<TeamCustomQuotaError>>}
 */
routes.teamMemberSpaceLimitsGetCustomQuota = function (arg) {
  return this.request('team/member_space_limits/get_custom_quota', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Remove users custom quota. A maximum of 1000 members can be specified in a
 * single call.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMemberSpaceLimitsRemoveCustomQuota
 * @arg {TeamCustomQuotaUsersArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<TeamRemoveCustomQuotaResult>>, DropboxResponseError.<TeamCustomQuotaError>>}
 */
routes.teamMemberSpaceLimitsRemoveCustomQuota = function (arg) {
  return this.request('team/member_space_limits/remove_custom_quota', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Set users custom quota. Custom quota has to be at least 15GB. A maximum of
 * 1000 members can be specified in a single call.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMemberSpaceLimitsSetCustomQuota
 * @arg {TeamSetCustomQuotaArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<TeamCustomQuotaResult>>, DropboxResponseError.<TeamSetCustomQuotaError>>}
 */
routes.teamMemberSpaceLimitsSetCustomQuota = function (arg) {
  return this.request('team/member_space_limits/set_custom_quota', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Adds members to a team. Permission : Team member management A maximum of 20
 * members can be specified in a single call. If no Dropbox account exists with
 * the email address specified, a new Dropbox account will be created with the
 * given email address, and that account will be invited to the team. If a
 * personal Dropbox account exists with the email address specified in the call,
 * this call will create a placeholder Dropbox account for the user on the team
 * and send an email inviting the user to migrate their existing personal
 * account onto the team. Team member management apps are required to set an
 * initial given_name and surname for a user to use in the team invitation and
 * for 'Perform as team member' actions taken on the user before they become
 * 'active'.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersAddV2
 * @arg {TeamMembersAddV2Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersAddLaunchV2Result>, DropboxResponseError.<void>>}
 */
routes.teamMembersAddV2 = function (arg) {
  return this.request('team/members/add_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Adds members to a team. Permission : Team member management A maximum of 20
 * members can be specified in a single call. If no Dropbox account exists with
 * the email address specified, a new Dropbox account will be created with the
 * given email address, and that account will be invited to the team. If a
 * personal Dropbox account exists with the email address specified in the call,
 * this call will create a placeholder Dropbox account for the user on the team
 * and send an email inviting the user to migrate their existing personal
 * account onto the team. Team member management apps are required to set an
 * initial given_name and surname for a user to use in the team invitation and
 * for 'Perform as team member' actions taken on the user before they become
 * 'active'.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersAdd
 * @arg {TeamMembersAddArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersAddLaunch>, DropboxResponseError.<void>>}
 */
routes.teamMembersAdd = function (arg) {
  return this.request('team/members/add', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Once an async_job_id is returned from members/add_v2 , use this to poll the
 * status of the asynchronous request. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersAddJobStatusGetV2
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersAddJobStatusV2Result>, DropboxResponseError.<AsyncPollError>>}
 */
routes.teamMembersAddJobStatusGetV2 = function (arg) {
  return this.request('team/members/add/job_status/get_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Once an async_job_id is returned from members/add , use this to poll the
 * status of the asynchronous request. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersAddJobStatusGet
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersAddJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.teamMembersAddJobStatusGet = function (arg) {
  return this.request('team/members/add/job_status/get', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Deletes a team member's profile photo. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersDeleteProfilePhotoV2
 * @arg {TeamMembersDeleteProfilePhotoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfoV2Result>, DropboxResponseError.<TeamMembersDeleteProfilePhotoError>>}
 */
routes.teamMembersDeleteProfilePhotoV2 = function (arg) {
  return this.request('team/members/delete_profile_photo_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Deletes a team member's profile photo. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersDeleteProfilePhoto
 * @arg {TeamMembersDeleteProfilePhotoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfo>, DropboxResponseError.<TeamMembersDeleteProfilePhotoError>>}
 */
routes.teamMembersDeleteProfilePhoto = function (arg) {
  return this.request('team/members/delete_profile_photo', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Get available TeamMemberRoles for the connected team. To be used with
 * members/set_admin_permissions_v2. Permission : Team member management.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersGetAvailableTeamMemberRoles
 * @returns {Promise.<DropboxResponse<TeamMembersGetAvailableTeamMemberRolesResult>, DropboxResponseError.<void>>}
 */
routes.teamMembersGetAvailableTeamMemberRoles = function () {
  return this.request('team/members/get_available_team_member_roles', null, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Returns information about multiple team members. Permission : Team
 * information This endpoint will return MembersGetInfoItem.id_not_found, for
 * IDs (or emails) that cannot be matched to a valid team member.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersGetInfoV2
 * @arg {TeamMembersGetInfoV2Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersGetInfoV2Result>, DropboxResponseError.<TeamMembersGetInfoError>>}
 */
routes.teamMembersGetInfoV2 = function (arg) {
  return this.request('team/members/get_info_v2', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Returns information about multiple team members. Permission : Team
 * information This endpoint will return MembersGetInfoItem.id_not_found, for
 * IDs (or emails) that cannot be matched to a valid team member.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersGetInfo
 * @arg {TeamMembersGetInfoArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<TeamMembersGetInfoError>>}
 */
routes.teamMembersGetInfo = function (arg) {
  return this.request('team/members/get_info', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Lists members of a team. Permission : Team information.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersListV2
 * @arg {TeamMembersListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersListV2Result>, DropboxResponseError.<TeamMembersListError>>}
 */
routes.teamMembersListV2 = function (arg) {
  return this.request('team/members/list_v2', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Lists members of a team. Permission : Team information.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersList
 * @arg {TeamMembersListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersListResult>, DropboxResponseError.<TeamMembersListError>>}
 */
routes.teamMembersList = function (arg) {
  return this.request('team/members/list', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Once a cursor has been retrieved from members/list_v2, use this to paginate
 * through all team members. Permission : Team information.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersListContinueV2
 * @arg {TeamMembersListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersListV2Result>, DropboxResponseError.<TeamMembersListContinueError>>}
 */
routes.teamMembersListContinueV2 = function (arg) {
  return this.request('team/members/list/continue_v2', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Once a cursor has been retrieved from members/list, use this to paginate
 * through all team members. Permission : Team information.
 * Route attributes:
 *   scope: members.read
 * @function Dropbox#teamMembersListContinue
 * @arg {TeamMembersListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersListResult>, DropboxResponseError.<TeamMembersListContinueError>>}
 */
routes.teamMembersListContinue = function (arg) {
  return this.request('team/members/list/continue', arg, 'team', 'api', 'rpc', 'members.read');
};

/**
 * Moves removed member's files to a different member. This endpoint initiates
 * an asynchronous job. To obtain the final result of the job, the client should
 * periodically poll members/move_former_member_files/job_status/check.
 * Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersMoveFormerMemberFiles
 * @arg {TeamMembersDataTransferArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchEmptyResult>, DropboxResponseError.<TeamMembersTransferFormerMembersFilesError>>}
 */
routes.teamMembersMoveFormerMemberFiles = function (arg) {
  return this.request('team/members/move_former_member_files', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Once an async_job_id is returned from members/move_former_member_files , use
 * this to poll the status of the asynchronous request. Permission : Team member
 * management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersMoveFormerMemberFilesJobStatusCheck
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncPollEmptyResult>, DropboxResponseError.<AsyncPollError>>}
 */
routes.teamMembersMoveFormerMemberFilesJobStatusCheck = function (arg) {
  return this.request('team/members/move_former_member_files/job_status/check', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Recover a deleted member. Permission : Team member management Exactly one of
 * team_member_id, email, or external_id must be provided to identify the user
 * account.
 * Route attributes:
 *   scope: members.delete
 * @function Dropbox#teamMembersRecover
 * @arg {TeamMembersRecoverArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamMembersRecoverError>>}
 */
routes.teamMembersRecover = function (arg) {
  return this.request('team/members/recover', arg, 'team', 'api', 'rpc', 'members.delete');
};

/**
 * Removes a member from a team. Permission : Team member management Exactly one
 * of team_member_id, email, or external_id must be provided to identify the
 * user account. Accounts can be recovered via members/recover for a 7 day
 * period or until the account has been permanently deleted or transferred to
 * another account (whichever comes first). Calling members/add while a user is
 * still recoverable on your team will return with
 * MemberAddResult.user_already_on_team. Accounts can have their files
 * transferred via the admin console for a limited time, based on the version
 * history length associated with the team (180 days for most teams). This
 * endpoint may initiate an asynchronous job. To obtain the final result of the
 * job, the client should periodically poll members/remove/job_status/get.
 * Route attributes:
 *   scope: members.delete
 * @function Dropbox#teamMembersRemove
 * @arg {TeamMembersRemoveArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncLaunchEmptyResult>, DropboxResponseError.<TeamMembersRemoveError>>}
 */
routes.teamMembersRemove = function (arg) {
  return this.request('team/members/remove', arg, 'team', 'api', 'rpc', 'members.delete');
};

/**
 * Once an async_job_id is returned from members/remove , use this to poll the
 * status of the asynchronous request. Permission : Team member management.
 * Route attributes:
 *   scope: members.delete
 * @function Dropbox#teamMembersRemoveJobStatusGet
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<AsyncPollEmptyResult>, DropboxResponseError.<AsyncPollError>>}
 */
routes.teamMembersRemoveJobStatusGet = function (arg) {
  return this.request('team/members/remove/job_status/get', arg, 'team', 'api', 'rpc', 'members.delete');
};

/**
 * Add secondary emails to users. Permission : Team member management. Emails
 * that are on verified domains will be verified automatically. For each email
 * address not on a verified domain a verification email will be sent.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSecondaryEmailsAdd
 * @arg {TeamAddSecondaryEmailsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamAddSecondaryEmailsResult>, DropboxResponseError.<TeamAddSecondaryEmailsError>>}
 */
routes.teamMembersSecondaryEmailsAdd = function (arg) {
  return this.request('team/members/secondary_emails/add', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Delete secondary emails from users Permission : Team member management. Users
 * will be notified of deletions of verified secondary emails at both the
 * secondary email and their primary email.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSecondaryEmailsDelete
 * @arg {TeamDeleteSecondaryEmailsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamDeleteSecondaryEmailsResult>, DropboxResponseError.<void>>}
 */
routes.teamMembersSecondaryEmailsDelete = function (arg) {
  return this.request('team/members/secondary_emails/delete', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Resend secondary email verification emails. Permission : Team member
 * management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSecondaryEmailsResendVerificationEmails
 * @arg {TeamResendVerificationEmailArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamResendVerificationEmailResult>, DropboxResponseError.<void>>}
 */
routes.teamMembersSecondaryEmailsResendVerificationEmails = function (arg) {
  return this.request('team/members/secondary_emails/resend_verification_emails', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Sends welcome email to pending team member. Permission : Team member
 * management Exactly one of team_member_id, email, or external_id must be
 * provided to identify the user account. No-op if team member is not pending.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSendWelcomeEmail
 * @arg {TeamUserSelectorArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamMembersSendWelcomeError>>}
 */
routes.teamMembersSendWelcomeEmail = function (arg) {
  return this.request('team/members/send_welcome_email', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's permissions. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetAdminPermissionsV2
 * @arg {TeamMembersSetPermissions2Arg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersSetPermissions2Result>, DropboxResponseError.<TeamMembersSetPermissions2Error>>}
 */
routes.teamMembersSetAdminPermissionsV2 = function (arg) {
  return this.request('team/members/set_admin_permissions_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's permissions. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetAdminPermissions
 * @arg {TeamMembersSetPermissionsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamMembersSetPermissionsResult>, DropboxResponseError.<TeamMembersSetPermissionsError>>}
 */
routes.teamMembersSetAdminPermissions = function (arg) {
  return this.request('team/members/set_admin_permissions', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's profile. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetProfileV2
 * @arg {TeamMembersSetProfileArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfoV2Result>, DropboxResponseError.<TeamMembersSetProfileError>>}
 */
routes.teamMembersSetProfileV2 = function (arg) {
  return this.request('team/members/set_profile_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's profile. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetProfile
 * @arg {TeamMembersSetProfileArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfo>, DropboxResponseError.<TeamMembersSetProfileError>>}
 */
routes.teamMembersSetProfile = function (arg) {
  return this.request('team/members/set_profile', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's profile photo. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetProfilePhotoV2
 * @arg {TeamMembersSetProfilePhotoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfoV2Result>, DropboxResponseError.<TeamMembersSetProfilePhotoError>>}
 */
routes.teamMembersSetProfilePhotoV2 = function (arg) {
  return this.request('team/members/set_profile_photo_v2', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Updates a team member's profile photo. Permission : Team member management.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSetProfilePhoto
 * @arg {TeamMembersSetProfilePhotoArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamMemberInfo>, DropboxResponseError.<TeamMembersSetProfilePhotoError>>}
 */
routes.teamMembersSetProfilePhoto = function (arg) {
  return this.request('team/members/set_profile_photo', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Suspend a member from a team. Permission : Team member management Exactly one
 * of team_member_id, email, or external_id must be provided to identify the
 * user account.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersSuspend
 * @arg {TeamMembersDeactivateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamMembersSuspendError>>}
 */
routes.teamMembersSuspend = function (arg) {
  return this.request('team/members/suspend', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Unsuspend a member from a team. Permission : Team member management Exactly
 * one of team_member_id, email, or external_id must be provided to identify the
 * user account.
 * Route attributes:
 *   scope: members.write
 * @function Dropbox#teamMembersUnsuspend
 * @arg {TeamMembersUnsuspendArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamMembersUnsuspendError>>}
 */
routes.teamMembersUnsuspend = function (arg) {
  return this.request('team/members/unsuspend', arg, 'team', 'api', 'rpc', 'members.write');
};

/**
 * Returns a list of all team-accessible namespaces. This list includes team
 * folders, shared folders containing team members, team members' home
 * namespaces, and team members' app folders. Home namespaces and app folders
 * are always owned by this team or members of the team, but shared folders may
 * be owned by other users or other teams. Duplicates may occur in the list.
 * Route attributes:
 *   scope: team_data.member
 * @function Dropbox#teamNamespacesList
 * @arg {TeamTeamNamespacesListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamNamespacesListResult>, DropboxResponseError.<TeamTeamNamespacesListError>>}
 */
routes.teamNamespacesList = function (arg) {
  return this.request('team/namespaces/list', arg, 'team', 'api', 'rpc', 'team_data.member');
};

/**
 * Once a cursor has been retrieved from namespaces/list, use this to paginate
 * through all team-accessible namespaces. Duplicates may occur in the list.
 * Route attributes:
 *   scope: team_data.member
 * @function Dropbox#teamNamespacesListContinue
 * @arg {TeamTeamNamespacesListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamNamespacesListResult>, DropboxResponseError.<TeamTeamNamespacesListContinueError>>}
 */
routes.teamNamespacesListContinue = function (arg) {
  return this.request('team/namespaces/list/continue', arg, 'team', 'api', 'rpc', 'team_data.member');
};

/**
 * Permission : Team member file access.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#teamPropertiesTemplateAdd
 * @deprecated
 * @arg {FilePropertiesAddTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesAddTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.teamPropertiesTemplateAdd = function (arg) {
  return this.request('team/properties/template/add', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Permission : Team member file access. The scope for the route is
 * files.team_metadata.write.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#teamPropertiesTemplateGet
 * @deprecated
 * @arg {FilePropertiesGetTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesGetTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.teamPropertiesTemplateGet = function (arg) {
  return this.request('team/properties/template/get', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Permission : Team member file access. The scope for the route is
 * files.team_metadata.write.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#teamPropertiesTemplateList
 * @deprecated
 * @returns {Promise.<DropboxResponse<FilePropertiesListTemplateResult>, DropboxResponseError.<FilePropertiesTemplateError>>}
 */
routes.teamPropertiesTemplateList = function () {
  return this.request('team/properties/template/list', null, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Permission : Team member file access.
 * Route attributes:
 *   scope: files.team_metadata.write
 * @function Dropbox#teamPropertiesTemplateUpdate
 * @deprecated
 * @arg {FilePropertiesUpdateTemplateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<FilePropertiesUpdateTemplateResult>, DropboxResponseError.<FilePropertiesModifyTemplateError>>}
 */
routes.teamPropertiesTemplateUpdate = function (arg) {
  return this.request('team/properties/template/update', arg, 'team', 'api', 'rpc', 'files.team_metadata.write');
};

/**
 * Retrieves reporting data about a team's user activity. Deprecated: Will be
 * removed on July 1st 2021.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamReportsGetActivity
 * @deprecated
 * @arg {TeamDateRange} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGetActivityReport>, DropboxResponseError.<TeamDateRangeError>>}
 */
routes.teamReportsGetActivity = function (arg) {
  return this.request('team/reports/get_activity', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Retrieves reporting data about a team's linked devices. Deprecated: Will be
 * removed on July 1st 2021.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamReportsGetDevices
 * @deprecated
 * @arg {TeamDateRange} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGetDevicesReport>, DropboxResponseError.<TeamDateRangeError>>}
 */
routes.teamReportsGetDevices = function (arg) {
  return this.request('team/reports/get_devices', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Retrieves reporting data about a team's membership. Deprecated: Will be
 * removed on July 1st 2021.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamReportsGetMembership
 * @deprecated
 * @arg {TeamDateRange} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGetMembershipReport>, DropboxResponseError.<TeamDateRangeError>>}
 */
routes.teamReportsGetMembership = function (arg) {
  return this.request('team/reports/get_membership', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Retrieves reporting data about a team's storage usage. Deprecated: Will be
 * removed on July 1st 2021.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamReportsGetStorage
 * @deprecated
 * @arg {TeamDateRange} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamGetStorageReport>, DropboxResponseError.<TeamDateRangeError>>}
 */
routes.teamReportsGetStorage = function (arg) {
  return this.request('team/reports/get_storage', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Endpoint adds Approve List entries. Changes are effective immediately.
 * Changes are committed in transaction. In case of single validation error -
 * all entries are rejected. Valid domains (RFC-1034/5) and emails
 * (RFC-5322/822) are accepted. Added entries cannot overflow limit of 10000
 * entries per team. Maximum 100 entries per call is allowed.
 * Route attributes:
 *   scope: team_info.write
 * @function Dropbox#teamSharingAllowlistAdd
 * @arg {TeamSharingAllowlistAddArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamSharingAllowlistAddResponse>, DropboxResponseError.<TeamSharingAllowlistAddError>>}
 */
routes.teamSharingAllowlistAdd = function (arg) {
  return this.request('team/sharing_allowlist/add', arg, 'team', 'api', 'rpc', 'team_info.write');
};

/**
 * Lists Approve List entries for given team, from newest to oldest, returning
 * up to `limit` entries at a time. If there are more than `limit` entries
 * associated with the current team, more can be fetched by passing the returned
 * `cursor` to sharing_allowlist/list/continue.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamSharingAllowlistList
 * @arg {TeamSharingAllowlistListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamSharingAllowlistListResponse>, DropboxResponseError.<TeamSharingAllowlistListError>>}
 */
routes.teamSharingAllowlistList = function (arg) {
  return this.request('team/sharing_allowlist/list', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Lists entries associated with given team, starting from a the cursor. See
 * sharing_allowlist/list.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamSharingAllowlistListContinue
 * @arg {TeamSharingAllowlistListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamSharingAllowlistListResponse>, DropboxResponseError.<TeamSharingAllowlistListContinueError>>}
 */
routes.teamSharingAllowlistListContinue = function (arg) {
  return this.request('team/sharing_allowlist/list/continue', arg, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Endpoint removes Approve List entries. Changes are effective immediately.
 * Changes are committed in transaction. In case of single validation error -
 * all entries are rejected. Valid domains (RFC-1034/5) and emails
 * (RFC-5322/822) are accepted. Entries being removed have to be present on the
 * list. Maximum 1000 entries per call is allowed.
 * Route attributes:
 *   scope: team_info.write
 * @function Dropbox#teamSharingAllowlistRemove
 * @arg {TeamSharingAllowlistRemoveArgs} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamSharingAllowlistRemoveResponse>, DropboxResponseError.<TeamSharingAllowlistRemoveError>>}
 */
routes.teamSharingAllowlistRemove = function (arg) {
  return this.request('team/sharing_allowlist/remove', arg, 'team', 'api', 'rpc', 'team_info.write');
};

/**
 * Sets an archived team folder's status to active. Permission : Team member
 * file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderActivate
 * @arg {TeamTeamFolderIdArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderMetadata>, DropboxResponseError.<TeamTeamFolderActivateError>>}
 */
routes.teamTeamFolderActivate = function (arg) {
  return this.request('team/team_folder/activate', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Sets an active team folder's status to archived and removes all folder and
 * file members. This endpoint cannot be used for teams that have a shared team
 * space. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderArchive
 * @arg {TeamTeamFolderArchiveArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderArchiveLaunch>, DropboxResponseError.<TeamTeamFolderArchiveError>>}
 */
routes.teamTeamFolderArchive = function (arg) {
  return this.request('team/team_folder/archive', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Returns the status of an asynchronous job for archiving a team folder.
 * Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderArchiveCheck
 * @arg {AsyncPollArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderArchiveJobStatus>, DropboxResponseError.<AsyncPollError>>}
 */
routes.teamTeamFolderArchiveCheck = function (arg) {
  return this.request('team/team_folder/archive/check', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Creates a new, active, team folder with no members. This endpoint can only be
 * used for teams that do not already have a shared team space. Permission :
 * Team member file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderCreate
 * @arg {TeamTeamFolderCreateArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderMetadata>, DropboxResponseError.<TeamTeamFolderCreateError>>}
 */
routes.teamTeamFolderCreate = function (arg) {
  return this.request('team/team_folder/create', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Retrieves metadata for team folders. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.read
 * @function Dropbox#teamTeamFolderGetInfo
 * @arg {TeamTeamFolderIdListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Array.<TeamTeamFolderGetInfoItem>>, DropboxResponseError.<void>>}
 */
routes.teamTeamFolderGetInfo = function (arg) {
  return this.request('team/team_folder/get_info', arg, 'team', 'api', 'rpc', 'team_data.content.read');
};

/**
 * Lists all team folders. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.read
 * @function Dropbox#teamTeamFolderList
 * @arg {TeamTeamFolderListArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderListResult>, DropboxResponseError.<TeamTeamFolderListError>>}
 */
routes.teamTeamFolderList = function (arg) {
  return this.request('team/team_folder/list', arg, 'team', 'api', 'rpc', 'team_data.content.read');
};

/**
 * Once a cursor has been retrieved from team_folder/list, use this to paginate
 * through all team folders. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.read
 * @function Dropbox#teamTeamFolderListContinue
 * @arg {TeamTeamFolderListContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderListResult>, DropboxResponseError.<TeamTeamFolderListContinueError>>}
 */
routes.teamTeamFolderListContinue = function (arg) {
  return this.request('team/team_folder/list/continue', arg, 'team', 'api', 'rpc', 'team_data.content.read');
};

/**
 * Permanently deletes an archived team folder. This endpoint cannot be used for
 * teams that have a shared team space. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderPermanentlyDelete
 * @arg {TeamTeamFolderIdArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<void>, DropboxResponseError.<TeamTeamFolderPermanentlyDeleteError>>}
 */
routes.teamTeamFolderPermanentlyDelete = function (arg) {
  return this.request('team/team_folder/permanently_delete', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Changes an active team folder's name. Permission : Team member file access.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderRename
 * @arg {TeamTeamFolderRenameArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderMetadata>, DropboxResponseError.<TeamTeamFolderRenameError>>}
 */
routes.teamTeamFolderRename = function (arg) {
  return this.request('team/team_folder/rename', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Updates the sync settings on a team folder or its contents.  Use of this
 * endpoint requires that the team has team selective sync enabled.
 * Route attributes:
 *   scope: team_data.content.write
 * @function Dropbox#teamTeamFolderUpdateSyncSettings
 * @arg {TeamTeamFolderUpdateSyncSettingsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamTeamFolderMetadata>, DropboxResponseError.<TeamTeamFolderUpdateSyncSettingsError>>}
 */
routes.teamTeamFolderUpdateSyncSettings = function (arg) {
  return this.request('team/team_folder/update_sync_settings', arg, 'team', 'api', 'rpc', 'team_data.content.write');
};

/**
 * Returns the member profile of the admin who generated the team access token
 * used to make the call.
 * Route attributes:
 *   scope: team_info.read
 * @function Dropbox#teamTokenGetAuthenticatedAdmin
 * @returns {Promise.<DropboxResponse<TeamTokenGetAuthenticatedAdminResult>, DropboxResponseError.<TeamTokenGetAuthenticatedAdminError>>}
 */
routes.teamTokenGetAuthenticatedAdmin = function () {
  return this.request('team/token/get_authenticated_admin', null, 'team', 'api', 'rpc', 'team_info.read');
};

/**
 * Retrieves team events. If the result's GetTeamEventsResult.has_more field is
 * true, call get_events/continue with the returned cursor to retrieve more
 * entries. If end_time is not specified in your request, you may use the
 * returned cursor to poll get_events/continue for new events. Many attributes
 * note 'may be missing due to historical data gap'. Note that the
 * file_operations category and & analogous paper events are not available on
 * all Dropbox Business plans /business/plans-comparison. Use
 * features/get_values
 * /developers/documentation/http/teams#team-features-get_values to check for
 * this feature. Permission : Team Auditing.
 * Route attributes:
 *   scope: events.read
 * @function Dropbox#teamLogGetEvents
 * @arg {TeamLogGetTeamEventsArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamLogGetTeamEventsResult>, DropboxResponseError.<TeamLogGetTeamEventsError>>}
 */
routes.teamLogGetEvents = function (arg) {
  return this.request('team_log/get_events', arg, 'team', 'api', 'rpc', 'events.read');
};

/**
 * Once a cursor has been retrieved from get_events, use this to paginate
 * through all events. Permission : Team Auditing.
 * Route attributes:
 *   scope: events.read
 * @function Dropbox#teamLogGetEventsContinue
 * @arg {TeamLogGetTeamEventsContinueArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<TeamLogGetTeamEventsResult>, DropboxResponseError.<TeamLogGetTeamEventsContinueError>>}
 */
routes.teamLogGetEventsContinue = function (arg) {
  return this.request('team_log/get_events/continue', arg, 'team', 'api', 'rpc', 'events.read');
};

/**
 * Get a list of feature values that may be configured for the current account.
 * Route attributes:
 *   scope: account_info.read
 * @function Dropbox#usersFeaturesGetValues
 * @arg {UsersUserFeaturesGetValuesBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<UsersUserFeaturesGetValuesBatchResult>, DropboxResponseError.<UsersUserFeaturesGetValuesBatchError>>}
 */
routes.usersFeaturesGetValues = function (arg) {
  return this.request('users/features/get_values', arg, 'user', 'api', 'rpc', 'account_info.read');
};

/**
 * Get information about a user's account.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#usersGetAccount
 * @arg {UsersGetAccountArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<UsersBasicAccount>, DropboxResponseError.<UsersGetAccountError>>}
 */
routes.usersGetAccount = function (arg) {
  return this.request('users/get_account', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Get information about multiple user accounts.  At most 300 accounts may be
 * queried per request.
 * Route attributes:
 *   scope: sharing.read
 * @function Dropbox#usersGetAccountBatch
 * @arg {UsersGetAccountBatchArg} arg - The request parameters.
 * @returns {Promise.<DropboxResponse<Object>, DropboxResponseError.<UsersGetAccountBatchError>>}
 */
routes.usersGetAccountBatch = function (arg) {
  return this.request('users/get_account_batch', arg, 'user', 'api', 'rpc', 'sharing.read');
};

/**
 * Get information about the current user's account.
 * Route attributes:
 *   scope: account_info.read
 * @function Dropbox#usersGetCurrentAccount
 * @returns {Promise.<DropboxResponse<UsersFullAccount>, DropboxResponseError.<void>>}
 */
routes.usersGetCurrentAccount = function () {
  return this.request('users/get_current_account', null, 'user', 'api', 'rpc', 'account_info.read');
};

/**
 * Get the space usage information for the current user's account.
 * Route attributes:
 *   scope: account_info.read
 * @function Dropbox#usersGetSpaceUsage
 * @returns {Promise.<DropboxResponse<UsersSpaceUsage>, DropboxResponseError.<void>>}
 */
routes.usersGetSpaceUsage = function () {
  return this.request('users/get_space_usage', null, 'user', 'api', 'rpc', 'account_info.read');
};

export { routes };
